<template>
  <div v-if="taskTypes" id="taskTypes">
      <app-bar page="Settings"></app-bar>
        <v-container>
        <v-card>
          <v-card-title class="px-4 pt-2">
            <settings-navigation class="border-bottom" page="TaskTypes"></settings-navigation>
          </v-card-title>
          <v-card-text>
            <v-row class="top-bar mt-0 mb-3">
                <v-col class="py-0">
                    <v-btn class="create-btn my-2" min-width="220px" color="primary" elevation="0" @click="displayDialog">Opprett oppgave</v-btn>
                </v-col>
            </v-row>
            <v-simple-table v-if="taskTypes.length > 0">
                <thead>
                    <tr>
                        <th>Navn</th>
                        <th>Utstyrskrav</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="taskType in filteredTaskTypes" :key="taskType.id" @click="populateDialog(taskType.id)">
                        <td><v-icon class="mr-3" size="30" rounded :color="taskType.colorHexCode">mdi-square-rounded</v-icon>{{taskType.name}}</td>
                        <td>{{getRequiredEquipment(taskType)}}</td>
                    </tr>
                </tbody>
            </v-simple-table>
            <p v-else class="mt-5 text-center">Det er ikke registrert noen oppgaver for ditt verksted</p>
          </v-card-text>
        </v-card>
        </v-container>
            <v-dialog v-model="dialogVisible" persistent max-width="600px">
                <v-card>
                  <v-card-title class="justify-center text-uppercase pt-4 pb-0">{{dialogHeading}}</v-card-title>
                    <v-card-text>
                        <v-alert v-model="hasError" class="mt-2" dense dismissible type="error">{{errorMessage}}</v-alert>
                        <v-form ref="form">
                            <v-row>
                                <v-col>
                                    <v-text-field autofocus v-model="name" label="Navn" :rules="[value => !!value || 'Navn må fylles inn']" validate-on-blur type="text" autocomplete="off"/>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>   
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <p><span class="color-text">Farge</span><span class="color-icon ml-2"><v-icon v-bind="attrs" medium v-on="on">mdi-information-outline</v-icon></span></p>
                                            <v-color-picker width="552" dot-size="15" mode="hexa" v-model="colorHexCode"></v-color-picker>
                                        </template>
                                        <span>Oppføringer av denne oppgaven på plantavlen vil tegnes med fargen du velger her</span>
                                    </v-tooltip>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <p>Utstyrskrav</p>
                                    <v-btn class="create-btn" color="primary" block @click="addRequiredEquipment()">Legg til utstyrskrav</v-btn>
                                </v-col>
                            </v-row>
                            <div class="required-equipment-rows mt-5">
                                <required-equipment-row v-for="equipment in requiredEquipment" :key="equipment.rowId"
                                :requiredEquipment="equipment"
                                v-on:removeRequiredEquipment="removeRequiredEquipment"
                                v-on:updateAmount="updateRequiredEquipmentAmount"
                                v-on:updateEquipment="updateRequiredEquipment"
                                >
                                </required-equipment-row>
                            </div>
                        </v-form>
                        <v-row class="mt-2">
                            <v-col><v-btn block color="primary" @click="save()">Lagre</v-btn></v-col>
                            <v-col><v-btn block color="warning" @click="closeDialog()">Avbryt</v-btn></v-col>
                            <v-col v-if="id"><v-btn block color="error" @click="showDeleteDialog()">Slett</v-btn></v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-dialog>
            <v-dialog v-model="deleteDialogVisible" persistent max-width="600px">
              <v-card>
                <v-card-title class="text-uppercase pt-5 pb-3">Slett oppgave</v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col>
                      <p>Skriv navnet på oppgaven du forsøker å slette (<b>{{name}}</b>) i tekstfeltet nedenfor for å bekrefte at du ønsker å slette denne oppgaven permanent.</p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field :placeholder="name" v-model="confirmDeleteText" autofocus />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col><v-btn block color="warning" @click="closeDeleteDialog()">Avbryt</v-btn></v-col>
                    <v-col><v-btn block color="error" :disabled="name !== confirmDeleteText" @click="deleteTaskType()">Slett oppgave</v-btn></v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-dialog>
        <feedback/>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import AppBar from '../components/AppBar.vue'
import SettingsNavigation from '../components/settings/SettingsNavigation.vue'
import Feedback from '../components/Feedback.vue'
import RequiredEquipmentRow from '../components/RequiredEquipmentRow.vue'

export default {
  components: {
    AppBar,
    SettingsNavigation,
    Feedback,
    RequiredEquipmentRow,
  },
  computed: {
    ...mapState(['taskTypes', 'equipment']),
    filteredTaskTypes() {
      return this.taskTypes.filter(
        taskType => taskType.name.toLowerCase().includes(this.search.toLowerCase()))
    },
  },
  data: () => ({
    id: null,
    name: '',
    colorHexCode: '',
    requiredEquipment: [],
    dialogVisible: false,
    deleteDialogVisible: false,
    confirmDeleteText: '',
    dialogHeading: 'Opprett oppgave',
    search: '',
    hasError: false,
    errorMessage: '',
  }),
  created() {
    this.$store.dispatch('getTaskTypes').then((response) => {
      if(response.data.status === 'SUCCESS') {
      }
    })
  },
  methods: {
    populateDialog(taskTypeId) {
      let taskType = this.taskTypes.find(taskType => taskType.id === taskTypeId)
      this.id = taskType.id
      this.name = taskType.name
      this.colorHexCode = taskType.colorHexCode
      this.requiredEquipment = this.populateRequiredEquipment(JSON.parse(JSON.stringify(taskType.requiredEquipment)))
      this.dialogHeading = 'Rediger oppgave'
      this.dialogVisible = true
    },
    populateRequiredEquipment(requiredEquipment) {
        for(let i = 0; i < requiredEquipment.length; i++) {
            requiredEquipment[i].rowId = Math.random().toString(36).substr(2, 9)
        }
        return requiredEquipment;
    },
    getRequiredEquipment(taskType) {
        let requiredEquipment = taskType.requiredEquipment;
        let result = '';
        requiredEquipment.forEach((value) => {
            if(value.amount && value.name) {
                result += value.amount + " stk. " + value.name.toLowerCase() + ", ";
            }
        });
        return result.substring(0, result.length - 2);
    },
    addRequiredEquipment() {
        let newEquipment = {
            rowId: Math.random().toString(36).substr(2, 9),
            amount: 1,
            id: null,
            name: null,
        }
        this.requiredEquipment.push(newEquipment);
    },
    updateRequiredEquipmentAmount(rowId, amount) {
        let indexToUpdate = this.requiredEquipment.findIndex(x => x.rowId == rowId);
        this.requiredEquipment[indexToUpdate].amount = amount
    },
    updateRequiredEquipment(rowId, equipmentId) {
        let equipment = this.equipment.find(x => x.id == equipmentId);
        let indexToUpdate = this.requiredEquipment.findIndex(x => x.rowId == rowId);
        this.requiredEquipment[indexToUpdate].name = equipment.name;
        this.requiredEquipment[indexToUpdate].id = equipment.id;
    },
    removeRequiredEquipment(equipment) {
        let indexToRemove = this.requiredEquipment.findIndex(x => x.rowId == equipment.rowId);
        this.requiredEquipment.splice(indexToRemove, 1)
    },
    save() {
      if(this.$refs.form.validate()) {
        let taskType = {
          name: this.name,
          colorHexCode: this.colorHexCode,
        }
        let requiredEquipmentList = []
        for(let i = 0; i < this.requiredEquipment.length; i++) {
            let equipment = this.requiredEquipment[i];
            requiredEquipmentList.push({'id' : equipment.id, 'amount' : equipment.amount});
        }
        taskType.requiredEquipment = JSON.stringify(requiredEquipmentList);
        if(!this.id) {
          this.createTaskType(taskType)
        } else {
          taskType.id = this.id
          this.updateTaskType(taskType)
        }
      }
    },
    createTaskType(taskType) {
      this.$store.dispatch('createTaskType', taskType).then((response) => {
        if(response.data.status === 'SUCCESS') {
          this.closeDialog();
        } else {
          this.hasError = true;
          this.errorMessage = response.data.message;
        }
      })
    },
    updateTaskType(taskType) {
      this.$store.dispatch('updateTaskType', taskType).then((response) => {
        if(response.data.status === 'SUCCESS') {
          this.closeDialog();
        } else {
          this.hasError = true;
          this.errorMessage = response.data.message;
        }
      })
    },
    deleteTaskType() {
      this.$store.dispatch('deleteTaskType', this.id).then((response) => {
        if(response.data.status === 'SUCCESS') {
          this.closeDialog();
        } else {
          this.hasError = true;
          this.errorMessage = response.data.message;
        }
        this.closeDeleteDialog();
      })
    },
    showDeleteDialog() {
      this.dialogVisible = false;
      this.deleteDialogVisible = true;
    },
    closeDeleteDialog() {
      this.deleteDialogVisible = false;
      this.confirmDeleteText = '';
    },
    displayDialog() {
        this.dialogVisible = true
        this.colorHexCode = '#' + Math.floor(Math.random()*16777215).toString(16)
        this.dialogHeading = 'Opprett oppgave'
    },
    closeDialog() {
      this.$refs.form.reset()
      this.$refs.form.resetValidation()
      this.hasError = false;
      this.errorMessage = '';
      this.dialogVisible = false;
      this.id = null
    }
  }
}
</script>

<style lang="scss" scoped>

  tbody tr {
    cursor: pointer;
  }

  .border-bottom {
    border-bottom: thin solid rgba(0,0,0,.12);
  }

  .v-color-picker__controls {
      padding: 100px !important;
  }

  @media (max-width: 575.99px) { 
    .create-btn {
      width: 100%;
    }
  }
  @media (min-width: 576px) and (max-width: 767.99px) { 

  }
  @media (min-width: 768px) and (max-width: 991.99px) {

  }
  @media (min-width: 992px) and (max-width: 1199.99px) { 

  }
</style>