<template>
    <div class="wrapper">
        <div class="left">
            <v-btn class="nav-btn" id="nav-left-btn" x-small max-height="23" @click="prevWeek"><v-icon>mdi-arrow-left-bold</v-icon></v-btn>
                <b id="week-number" class="no-select">Uke {{week}}</b>
            <v-btn class="nav-btn" id="nav-right-btn" x-small  max-height="23" @click="nextWeek"><v-icon>mdi-arrow-right-bold</v-icon></v-btn>
        </div>
        <div class="right">
            <v-simple-table id="nav-days">
                <thead>
                    <tr>
                        <th v-for="n in 5" :key="n" @contextmenu="showContextMenu"><h3>{{formatDayHeader(n)}}</h3></th>
                    </tr>
                </thead>
            </v-simple-table>
        </div>
        <v-menu class="context-menu" v-model="contextMenuVisible" :position-x="contextMenuX" :position-y="contextMenuY" absolute offset-y>
        <v-list class="context-menu-list">
            <!-- <v-list-item @click="toggleDay" :disabled='cutDisabled' link class="context-menu-item">
                <v-list-item-title><v-icon class="menu-icon">mdi-calendar-check</v-icon>Marker som helligdag</v-list-item-title>
            </v-list-item> -->
        </v-list>
        </v-menu>
    </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
    name: 'dashboard-header',
    created() {
        const time = {
            year: this.$moment().format('yyyy'),
            week: this.$moment().format('w'),
            monday: this.$moment().startOf('week').format('L')
        }
        this.$store.commit('setTime', time)
        this.getWeekData(this.year, this.week, this.monday)
    },
    data: function () {
        return {
            contextMenuVisible: false,
            contextMenuX: 0,
            contextMenuY: 0,
        }
    },
    computed: {
        ...mapState(['shifts', 'year', 'week', 'monday'])
    },
    methods: {
        showContextMenu (e) {
            e.preventDefault()
            this.contextMenuVisible = false
            this.contextMenuX = e.clientX
            this.contextMenuY = e.clientY
            this.$nextTick(() => {
                this.contextMenuVisible = true
            })
        },
        getWeekData(year, week, monday) {
            this.$store.dispatch('getWeekData', {year: year, week: week, monday: monday}).then((response) => {
                this.$emit('weekChanged');
            })
        },
        prevWeek() {
            this.$store.commit("decrementWeek");
            this.getWeekData(this.year, this.week, this.monday)
        },
        nextWeek() {
            this.$store.commit("incrementWeek");
            this.getWeekData(this.year, this.week, this.monday)
        },
        formatDayHeader(dayOfWeek) {
            if(this.shifts[0]) {
                return this.capFirstLetter(this.$moment(this.monday, 'L').add(dayOfWeek-1, 'days').format('dddd L'))
            }
        },
        capFirstLetter(string) {
            return string.charAt(0).toUpperCase() + string.slice(1)
        },
    }
}
</script>

<style>
    #nav-days th{
        background-color: lightgray;
        text-align: center;
        border-radius: 0px;
        height: 36px;
        padding: 0px;
        margin: 0px;
    }
    #week-number {
        color: rgba(0,0,0,.6);
        margin: 0px 18px;
        line-height: 1.8;
    }
    .nav-btn {
        margin-top: -2px;
        height: 25px !important;
        box-shadow: none;
        color: lightgray !important;
        background-color: rgba(0,0,0,.4) !important;
    }
    .wrapper {
        display: flex;
        align-items: center;
    }
    .left {
        height: 36px;
        padding-top: 4px;
        background-color: lightgray;
        text-align: center;
        flex: 0 0 200px;
        border-bottom: thin solid rgba(0,0,0,.12);
    }
    .right {
        flex: 1;
    }
</style>

