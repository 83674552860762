import axios from 'axios'
import store from './store'

let baseURL = process.env.NODE_ENV === 'development' ? "http://10.0.0.107:9000/api/" : "/api/"

const Service = axios.create({
  baseURL: baseURL
})

Service.interceptors.request.use((config) => {
  config.headers['token'] = store.state.accessToken ? store.state.accessToken.token : null
  return config
})

Service.interceptors.response.use(undefined, err => {
  const response = err.response
  if (response.status === 401) {
    if (store.state.accessToken != null) {
      store.dispatch('logout', { infoText: 'Sesjonen din har utløpt og du har blitt logget av systemet', error: true })
    }
  }
})

export default {
  login(formData) {
    return Service.post('login', formData, {})
  },
  logout() {
    return Service.post('logout')
  },
  getWeekData(workshopId, year, week, monday) {
    return Service.get("shifts/" + workshopId + "/" + year + "/" + week, {params: {monday: monday}})
  },
  getSettings(workshopId) {
    return Service.get("settings/" + workshopId);
  },

  // Settings - Users

  getUsers(workshopId) {
    return Service.get("settings/users/" + workshopId);
  },
  createUser(workshopId, formData) {
    return Service.post("settings/users/" + workshopId, formData, {})
  },
  updateUser(workshopId, userId, formData) {
    return Service.put("settings/users/" + workshopId + "/" + userId, formData, {})
  },
  deleteUser(workshopId, userId) {
    return Service.delete("settings/users/" + workshopId + "/" + userId, null, {})
  },

  // Settings - ShiftTypes

  getShiftTypes(workshopId) {
    return Service.get("settings/shift-types/" + workshopId)
  },
  createShiftType(workshopId, formData) {
    return Service.post("settings/shift-types/" + workshopId, formData, {})
  },
  updateShiftType(workshopId, shiftTypeId, formData) {
    return Service.put("settings/shift-types/" + workshopId + "/" + shiftTypeId, formData, {})
  },
  deleteShiftType(workshopId, shiftTypeId) {
    return Service.delete("settings/shift-types/" + workshopId + "/" + shiftTypeId, null, {})
  },

  // Settings - Departments

  getDepartments(workshopId) {
    return Service.get("settings/departments/" + workshopId);
  },
  createDepartment(workshopId, formData) {
    return Service.post("settings/departments/" + workshopId, formData, {})
  },
  updateDepartment(workshopId, departmentId, formData) {
    return Service.put("settings/departments/" + workshopId + "/" + departmentId, formData, {})
  },
  deleteDepartment(workshopId, departmentId) {
    return Service.delete("settings/departments/" + workshopId + "/" + departmentId, null, {})
  },

  // Settings - Mechanics

  getMechanics(workshopId) {
    return Service.get("settings/mechanics/" + workshopId)
  },
  createMechanic(workshopId, formData) {
    return Service.post("settings/mechanics/" + workshopId, formData, {})
  },
  updateMechanic(workshopId, mechanicId, formData) {
    return Service.put("settings/mechanics/" + workshopId + "/" + mechanicId, formData, {})
  },
  deleteMechanic(workshopId, mechanicId) {
    return Service.delete("settings/mechanics/" + workshopId + "/" + mechanicId, null, {})
  },

  // Settings - Equipment

  getEquipment(workshopId) {
    return Service.get("settings/equipment/" + workshopId)
  },
  createEquipment(workshopId, formData) {
    return Service.post("settings/equipment/" + workshopId, formData, {})
  },
  updateEquipment(workshopId, equipmentId, formData) {
    return Service.put("settings/equipment/" + workshopId + "/" + equipmentId, formData, {})
  },
  deleteEquipment(workshopId, equipmentId) {
    return Service.delete("settings/equipment/" + workshopId + "/" + equipmentId, null, {})
  },

  // Settings - TaskTypes

  getTaskTypes(workshopId) {
    return Service.get("settings/task-types/" + workshopId)
  },
  createTaskType(workshopId, formData) {
    return Service.post("settings/task-types/" + workshopId, formData, {})
  },
  updateTaskType(workshopId, taskTypeId, formData) {
    return Service.put("settings/task-types/" + workshopId + "/" + taskTypeId, formData, {})
  },
  deleteTaskType(workshopId, taskTypeId) {
    return Service.delete("settings/task-types/" + workshopId + "/" + taskTypeId, null, {})
  },

  // Profile
  
  updateProfile(id, formData) {
    return Service.put("profile/" + id, formData, {})
  }
}