<template>
    <v-snackbar timeout="5000" :value="!!feedback" @input="hide()" bottom right>
        {{ feedback }}
        <template v-slot:action="{ attrs }">
            <v-btn text v-bind="attrs" @click="hide()">Lukk</v-btn>
        </template>
    </v-snackbar>
</template>

<script>
import { mapState } from 'vuex'
export default {
    name: 'feedback',
    computed: {
        ...mapState(['feedback']),
    },
    methods: {
        hide() {
            this.$store.commit('setFeedback', null);
        },
    },
}
</script>
<style>
.v-snack__wrapper {
    background-color: #0c2754 !important;
    -webkit-box-shadow: inset 0px 0px 3px 1px rgba(6,29,66,1) !important;
    -moz-box-shadow: inset 0px 0px 3px 1px rgba(6,29,66,1) !important;
    box-shadow: inset 0px 0px 3px 1px rgba(6,29,66,1) !important;
}
</style>