import { mapState } from 'vuex'

export const taskCollisionMixin = {
    methods: {
        getTaskHours(task) {
            let startHour = Number(this.$moment(task.startTime, 'DD.MM.YYYY HH:mm').format("H"))
            let endHour = startHour + task.duration
            let hours = []
            for(let i = startHour; i < endHour; i++) {
              hours.push(i)
            }
            return hours
          },
          formatHour(hour) {
            if(hour < 10) {
              hour = "0"+ hour
            }
            return hour + ":00"
          },
    },
    computed: {
        ...mapState({
            reservationTask: state => state.reservationTask,
            selectedTask: state => state.selectedTask,
            customer: state => state.selectedTask.customer,
            plateNumber: state => state.selectedTask.plateNumber,
            taskType: state => state.selectedTask.taskType,
            duration: state => state.selectedTask.duration,
            shiftMechanicName: state => state.selectedTask.shiftMechanic.mechanic.name,
            shiftMechanicId: state => state.selectedTask.shiftMechanic.id,
            shiftType: state => state.selectedTask.shiftType,
            startHour: state => state.selectedTask.startHour,
            endHour: state => state.selectedTask.endHour,
            date: state => state.selectedTask.date
          }),
          taskTypeHint: function() {
            let taskTypeHint = ""
            this.taskType.requiredEquipment.forEach(equipment => {
              let availability = this.maxDurationMap.get(equipment.name) > 0 ? " (tilgjengelig i " + this.maxDurationMap.get(equipment.name) + " timer)" : " (ikke tilgjengelig)"
              taskTypeHint += "Krever " +  equipment.amount + " stk " + equipment.name.toLowerCase() + availability +  "\n"
            })
            return taskTypeHint
          },
          durationHint: function() {
            return "kl. " + this.formatHour(this.startHour) + " - " + this.formatHour(this.startHour + this.duration)
          },
          startTime: function() {
            return this.$moment(this.date + " " + this.startHour, "dddd DD.MM.YYYY H").format("DD.MM.YYYY HH:mm")
          },
        availableHours: function() {
            let availableHours = this.shiftType ? this.shiftType.endHour - this.startHour : 1
            this.maxDurationMap.forEach((key) => {
              if(key < availableHours) {
                availableHours = key;
              }
            })
            return this.firstCollidingStartHour ? Math.min(availableHours, this.firstCollidingStartHour) : availableHours;
          },
        firstCollidingStartHour: function() {
            let candidates = this.$store.state.tasks.filter(task =>
                task.taskType.name != 'Reservasjon'
                && task.shiftMechanicId == this.shiftMechanicId
                && this.$moment(task.startTime, 'DD.MM.YYYY HH:mm').isSame(this.$moment(this.startTime, 'DD.MM.YYYY HH:mm'), 'day')
                && this.$moment(task.startTime, 'DD.MM.YYYY HH:mm').isAfter(this.$moment(this.startTime, 'DD.MM.YYYY HH:mm')))
            if(candidates.length > 0) {
              let firstStartHour = 99;
              candidates.forEach(candidate => {
                let candidateStartHour = this.$moment(candidate.startTime, 'DD.MM.YYYY HH:mm').format("HH");
                if(candidateStartHour <= firstStartHour) {
                  firstStartHour = candidateStartHour;
                }
              })
              return firstStartHour - this.startHour;
            }
          },
          maxDurationMap: function() {
            let results = new Map()
            let tasks = this.collidingTasks
            for(let i = 0; i < this.taskType.requiredEquipment.length; i++) {
              let hourEquipmentMap = new Map()
              let requiredEquipment = this.taskType.requiredEquipment[i]
              let totalNumEquipment = this.$store.state.equipment.find(equipment => equipment.id == requiredEquipment.id).amount
              let hoursAvailable = 0
              for(let j = this.shiftType.startHour; j < this.shiftType.endHour; j++) {
                hourEquipmentMap.set(j, 0)
              }
              for(let j = 0; j < tasks.length; j++) {
                let otherTask = tasks[j]
                let otherEquipment = otherTask.taskType.requiredEquipment.find(eq => eq.id == requiredEquipment.id)
                if(otherEquipment) {
                  let otherTaskHours = this.getTaskHours(otherTask)
                  otherTaskHours.forEach(hour => {
                    hourEquipmentMap.set(hour, hourEquipmentMap.get(hour) + otherEquipment.amount)
                    }
                  )
                }
              }
              for(let j = this.startHour; j < this.shiftType.endHour; j++) {
                if(hourEquipmentMap.get(j) + requiredEquipment.amount <= totalNumEquipment) {
                  hoursAvailable++
                } else {
                  break
                }
              }
              results.set(requiredEquipment.name, hoursAvailable)
            }
            return results
          },
          collidingTasks: function() {
            let collidingTasks = []
            let tasks = this.tasksWithSameDateAndShiftType 
            let startTime = this.$moment(this.startTime, "DD.MM.YYYY HH:mm")
            for(let i = 0; i < this.taskType.requiredEquipment.length; i++) {
              for(let j = 0; j < tasks.length; j++) {
                let otherTask = tasks[j]
                let otherEndTime = this.$moment(otherTask.endTime, "DD.MM.YYYY HH:mm")
                if(otherEndTime.isAfter(startTime)) {
                  if(!collidingTasks.find(t => t.id == otherTask.id)) {
                    collidingTasks.push(otherTask)
                  }
                }  
              }
            }
            return collidingTasks
        },
        tasksWithSameDateAndShiftType: function() {
            return this.$store.state.tasks.filter(task => this.$moment(task.startTime, 'DD.MM.YYYY HH:mm').isSame(this.$moment(this.startTime, 'DD.MM.YYYY HH:mm'), 'day') && task.shiftTypeId == this.shiftType.id && task.id != this.selectedTask.id)
        },
    }
}