<template>
    <v-row class="equipment-row">
        <v-col cols="2">
            <v-text-field height="2.0rem" class="amount" :value="requiredEquipment.amount" label="Antall" type="number" dense @change="updateAmount" :rules="[value => !!value || 'Antall må velges']" autocomplete="off"/>
        </v-col>
        <v-col cols="6">
            <v-select class="equipment mt-0 pt-0" :items="equipment" :value="requiredEquipment" label="Utstyr" item-text="name" item-value="id" @change="updateEquipment" :rules="[value => !!value || 'Utstyr må velges']"></v-select>    
        </v-col>
        <v-col cols="4">
            <v-btn class="delete-btn primary" block @click="removeRow">Fjern</v-btn>
        </v-col>
    </v-row>
</template>

<script>
import { mapState } from 'vuex'
export default {
    name: 'required-equipment-row',
    props: ['requiredEquipment'],
    data: () => {
        return {
        }
    },
    computed: {
        ...mapState(['equipment']),
    },
    methods: {
        updateAmount(e) {
            this.$emit('updateAmount', this.requiredEquipment.rowId, e)
        },
        updateEquipment(e) {
            this.$emit('updateEquipment', this.requiredEquipment.rowId, e)
        },  
        removeRow() {
            this.$emit('removeRequiredEquipment', this.requiredEquipment)
        }
    }
}
</script>

<style lang="scss" scoped>
    .equipment-row .amount {
        margin-top: 0px;
    }
</style>