<template>
    <div v-if="shiftTypes" id="shiftTypes">
        <app-bar page="Settings"></app-bar>
        <v-container>
          <v-card>
            <v-card-title class="px-4 pt-2">
              <settings-navigation class="border-bottom" page="ShiftTypes"></settings-navigation>
            </v-card-title>
          <v-card-text>
            <v-row class="top-bar mt-0 mb-3">
                <v-col class="py-0">
                    <v-btn class="create-btn my-2" min-width="220px" color="primary" elevation="0" @click="displayDialog">Opprett skift</v-btn>
                </v-col>
            </v-row>
            <v-simple-table v-if="shiftTypes.length > 0">
                <thead>
                    <tr>
                        <th>Navn</th>
                        <th>Klokkeslett</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="shiftType in filteredShiftTypes" :key="shiftType.id" @click="populateDialog(shiftType.id)">
                        <td>{{shiftType.name}}</td>
                        <td>{{shiftTypeTime(shiftType)}}</td>
                    </tr>
                </tbody>
            </v-simple-table>
            <p v-else class="mt-5 text-center">Det er ikke registrert noen skift for ditt verksted</p>
          </v-card-text>
          </v-card>
        </v-container>
          <v-dialog v-model="dialogVisible" persistent max-width="600px">
              <v-card>
                <v-card-title class="justify-center text-uppercase pt-4 pb-0">{{dialogHeading}}</v-card-title>
                  <v-card-text>
                      <v-alert v-model="hasError" class="mt-2" dense dismissible type="error">{{errorMessage}}</v-alert>
                      <v-form ref="form">
                          <v-row>
                              <v-col>
                                  <v-text-field autofocus v-model="name" label="Navn" :rules="[value => !!value || 'Navn må fylles inn']" type="text" autocomplet="off" validate-on-blur/>
                              </v-col>
                          </v-row>
                          <v-row>
                              <v-col>
                                  <v-select v-model="startTime" :items="hours" :menu-props="{ top: true }" label="Starttidspunkt" autocomplete="off"></v-select>
                              </v-col>
                              <v-col>
                                  <v-select v-model="endTime" :items="hours" :menu-props="{ top: true }" label="Sluttidspunkt" autocomplete="off"></v-select>
                              </v-col>
                          </v-row>
                      </v-form>
                      <v-row class="mt-2">
                          <v-col><v-btn block color="primary" @click="save()">Lagre</v-btn></v-col>
                          <v-col><v-btn block color="warning" @click="closeDialog()">Avbryt</v-btn></v-col>
                          <v-col v-if="id"><v-btn block color="error" @click="showDeleteDialog()">Slett</v-btn></v-col>
                      </v-row>
                  </v-card-text>
              </v-card>
          </v-dialog>
          <v-dialog v-model="deleteDialogVisible" persistent max-width="600px">
            <v-card>
              <v-card-title class="text-uppercase pt-5 pb-3">Slett skift</v-card-title>
              <v-card-text>
                <v-row>
                  <v-col>
                    <p>Skriv navnet på skiftet du forsøker å slette (<b>{{name}}</b>) i tekstfeltet nedenfor for å bekrefte at du ønsker å slette dette skiftet permanent.</p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field :placeholder="name" v-model="confirmDeleteText" autofocus type="text" autocomplete="off" />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col><v-btn block color="warning" @click="closeDeleteDialog()">Avbryt</v-btn></v-col>
                  <v-col><v-btn block color="error" :disabled="name !== confirmDeleteText" @click="deleteShiftType()">Slett skift</v-btn></v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-dialog>
        <feedback/>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import AppBar from '../components/AppBar.vue'
import SettingsNavigation from '../components/settings/SettingsNavigation.vue'
import Feedback from '../components/Feedback.vue'

export default {
  components: {
    AppBar,
    SettingsNavigation,
    Feedback,
  },
  computed: {
    ...mapState(['shiftTypes']),
    filteredShiftTypes() {
      return this.shiftTypes.filter(
        shiftType => shiftType.name.toLowerCase().includes(this.search.toLowerCase()))
    },
  },
  data: () => ({
    id: null,
    name: '',
    startTime: null,
    endTime: null,
    dialogVisible: false,
    deleteDialogVisible: false,
    confirmDeleteText: '',
    dialogHeading: 'Opprett oppgave',
    search: '',
    hasError: false,
    errorMessage: '',
    hours: null
  }),
  created() {
    this.$store.dispatch('getShiftTypes').then((response) => {
      if(response.data.status === 'SUCCESS') {
      }
    })
    this.hours = this.getHours()
  },
  methods: {
    populateDialog(shiftTypeId) {
      let shiftType = this.shiftTypes.find(shiftType => shiftType.id === shiftTypeId)
      this.id = shiftType.id
      this.name = shiftType.name
      this.startTime = this.formatHour(shiftType.startHour);
      this.endTime = this.formatHour(shiftType.endHour);
      this.dialogHeading = 'Rediger skift'
      this.dialogVisible = true
    },
    shiftTypeTime(shiftType) {
        let startHour = shiftType.startHour > 9 ? shiftType.startHour + ":00" : "0" + shiftType.startHour + ":00"
        let endHour = shiftType.endHour > 9 ? shiftType.endHour + ":00" : "0" + shiftType.endHour + ":00"
        return startHour + " - " + endHour
    },
    getHours() {
        let hours = [];
        for(let i = 0; i <= 23; i++) {
            hours.push(this.formatHour(i))
        }
        return hours
    },
    formatHour(hour) {
      return hour > 9 ? hour + ':00' : "0" + hour + ":00";
    },
    save() {
      if(this.$refs.form.validate()) {
        let shiftType = {
          name: this.name,
          startTime: this.startTime,
          endTime: this.endTime,
        }
        if(!this.id) {
          this.createShiftType(shiftType)
        } else {
          shiftType.id = this.id
          this.updateShiftType(shiftType)
        }
      }
    },
    createShiftType(shiftType) {
        this.$store.dispatch('createShiftType', shiftType).then((response) => {
            if(response.data.status === 'SUCCESS') {
                this.closeDialog();
            } else {
                this.hasError = true;
                this.errorMessage = response.data.message;
            }
        })
    },
    updateShiftType(shiftType) {
      this.$store.dispatch('updateShiftType', shiftType).then((response) => {
        if(response.data.status === 'SUCCESS') {
          this.closeDialog();
        } else {
          this.hasError = true;
          this.errorMessage = response.data.message;
        }
      })
    },
    deleteShiftType() {
      this.$store.dispatch('deleteShiftType', this.id).then((response) => {
        if(response.data.status === 'SUCCESS') {
          this.closeDialog();
        } else {
          this.hasError = true;
          this.errorMessage = response.data.message;
        }
        this.closeDeleteDialog();
      })
    },
    showDeleteDialog() {
      this.dialogVisible = false;
      this.deleteDialogVisible = true;
    },
    closeDeleteDialog() {
      this.deleteDialogVisible = false;
      this.confirmDeleteText = '';
    },
    displayDialog() {
        this.dialogVisible = true
        this.dialogHeading = 'Opprett skift'
    },
    closeDialog() {
      this.$refs.form.reset()
      this.$refs.form.resetValidation()
      this.hasError = false;
      this.errorMessage = '';
      this.dialogVisible = false;
      this.deleteDialogVisible = false;
      this.id = null
    }
  }
}
</script>

<style lang="scss" scoped>

  tbody tr {
    cursor: pointer;
  }

  .border-bottom {
    border-bottom: thin solid rgba(0,0,0,.12);
  }

  @media (max-width: 575.99px) { 
    .create-btn {
      width: 100%;
    }
  }
  @media (min-width: 576px) and (max-width: 767.99px) { 

  }
  @media (min-width: 768px) and (max-width: 991.99px) {

  }
  @media (min-width: 992px) and (max-width: 1199.99px) { 

  }
</style>