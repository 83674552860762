<template>
  <v-dialog v-model="visible" v-if="selectedTask" persistent max-width="600">
    <v-card class="pa-2">
      <v-card-title class="justify-center text-uppercase py-3">{{dialogHeading}}</v-card-title>
      <v-card-text>
        <v-form>
          <v-row>
            <v-col>
              <v-text-field type="text" autocomplete="off" :value="shiftMechanicName" label="Mekaniker" disabled></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>  
              <v-text-field type="text" autocomplete="off" :value="date" label="Dato" disabled></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field type="text" autocomplete="off" :value="customer" @input="updateCustomer" label="Kunde" autofocus></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field type="text" autocomplete="off" :value="plateNumber" @input="updatePlateNumber" label="Registreringsnummer"></v-text-field>
            </v-col>
          </v-row>
          <v-row class="select-row">
            <v-col>
              <v-autocomplete :value="taskType" :items="taskTypes" @change="updateTaskType" label="Arbeidsart" item-text="name" item-value="name" return-object :hint="taskTypeHint" persistent-hint>
                <template v-slot:item="data">
                  <template>
                    <v-list-item-avatar>
                      <v-icon :color="data.item.colorHexCode">mdi-stop</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title v-html="data.item.name"></v-list-item-title>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete> 
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <p class="label mb-1 mt-3">Antall timer</p>
              <v-btn-toggle :value="duration" @change="updateDuration" mandatory style="display: flex;">
                <v-btn v-for="n in 8" :key="n" :value="n" :disabled="availableHours < n" style="flex: 1">{{n}}</v-btn>
              </v-btn-toggle>
              <p class="label mt-2">{{durationHint}}</p>
            </v-col>
          </v-row>
          <v-row class="mt-2">
            <v-col><v-btn block color="primary" @click="createTask()">Lagre</v-btn></v-col>
            <v-col><v-btn block color="warning" @click="cancel()">Avbryt</v-btn></v-col>
            <v-col v-if="this.selectedTask.id"><v-btn block color="error" @click="deleteTask()">Slett</v-btn></v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'
import { webSocketMixin } from '../mixins/webSocketMixin'
import { taskCollisionMixin } from '../mixins/taskCollisionMixin'

export default {
  name: 'task-dialog',
  props: ['webSocket', 'taskTypes', 'visible'],
  mixins: [webSocketMixin, taskCollisionMixin],
  methods: {
    updateCustomer (e) {
      this.$store.commit('updateCustomer', e)
    },
    updatePlateNumber (e) {
      this.$store.commit('updatePlateNumber', e)
    },
    updateTaskType (e) {
      this.$store.commit('updateTaskType', e)
    },
    updateDuration (e) {
      console.log(e)
      this.$store.commit('updateDuration', e)
    },
    createTask() {
      let task = {
        startTime: this.$moment(this.date, 'dddd L').set({"hour": this.startHour, "minute": 0}).format('yyyy-MM-DDTHH:mm'),
        endTime: this.$moment(this.date, 'dddd L').set({"hour": (this.startHour + this.duration), "minute": 0}).format('yyyy-MM-DDTHH:mm'),
        shiftMechanicId: this.shiftMechanicId,
        customer: this.customer,
        plateNumber: this.plateNumber,
        taskTypeId: this.taskType.id,
      }
      if(this.selectedTask.id) {
        task.id = this.selectedTask.id
        this.webSocket.send(this.createWebSocketMessage(this.$store.state.accessToken.token, 'UPDATE_TASK', JSON.stringify(task)))
      } else {
        task.reservationTaskId = this.reservationTask.id
        this.webSocket.send(this.createWebSocketMessage(this.$store.state.accessToken.token, 'CREATE_TASK', JSON.stringify(task)))
      }
    },
    deleteTask() {
      this.webSocket.send(this.createWebSocketMessage(this.$store.state.accessToken.token, 'DELETE_TASK', JSON.stringify(this.selectedTask.id)))
    },
    cancel() {
      this.$emit('taskDialogClosed')
    }
  },
  computed: {
    dialogHeading() {
      return this.selectedTask.id ? 'Rediger oppgave' : 'Registrer oppgave'
    },
  }
}
</script>

<style lang="scss" scoped>
  .label {
    color: rgba(0,0,0,.6);
    height: 20px;
    line-height: 20px;
    letter-spacing: normal;
    font-size: 16px;
    transform-origin: top left;
    transform: scale(.75);
    overflow: hidden;
    text-overflow: ellipsis;
    top: 6px;
    white-space: nowrap;
    pointer-events: none;
  }
  .v-avatar {
    margin: 0px !important;
  }
  .v-select-list .v-icon {
    font-size: 40px;
  }
</style>