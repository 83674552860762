<template>
  <div id="login">
    <v-card id="login-card" class="mx-auto" max-width="600">
      <v-form>
        <v-card-title id="login-title" class="justify-center mb-3">
          <h1>Logg på</h1>
        </v-card-title>
        <v-card-text id="login-text">
          <p v-if="infoText" class="info-text" :class="{'error-text': error}">{{infoText}}</p>
          <v-text-field 
            v-model="email" 
            label="E-post"
            autocomplete="username"
            prepend-icon="mdi-account-circle"
            @input="errorMessage = ''"
            @keyup.enter="login"
            autofocus
          />
          <v-text-field
            v-model="password"
            label="Passord"
            autocomplete="current-password"
            prepend-icon="mdi-lock"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showPassword ? 'text' : 'password'"
            @input="errorMessage = ''"
            @click:append="showPassword = !showPassword"
            @keyup.enter="login"
          />
        </v-card-text>
        <v-card-actions class="mt-2 mb-4 px-4">
          <v-btn type="button" @click="login" block color="primary">Logg på</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showPassword: false,
      email: '',
      password: '',
      infoText: this.$route.params.infoText,
      error: this.$route.params.error
    }
  },
  methods: {
    login() {
      this.$store
        .dispatch('login', { email: this.email, password: this.password })
        .then((response) => {
          if (!response) {
            return
          }
          if (response.data.status === 'ERROR') {
            this.infoText = response.data.message
            this.error = true;
          } else {
            this.$router.push({ name: 'Dashboard' })
          }
        })
        .catch((error) => {
          console.warn(error)
        })
    },
    clearError() {
      this.infoText = '';
      this.error = false;
    }
  }
}
</script>

<style lang="scss" scoped>
  #login-card {
    margin-top: 3rem;
    padding: 1rem;
  }
  #login-text {
    text-align: center;
  }
  .login-text .info-text {
  font-size: 16px;
  }
  .error-text {
    color: red;
  }
  @media (max-width: 575.99px) { 
    #login-card {
      max-width: 90% !important;
      margin-top: 1.7rem;
      padding: 0.5rem;
    }
  }
  @media (min-width: 576px) and (max-width: 767.99px) { 
  }
  @media (min-width: 768px) and (max-width: 991.99px) {
    #login-card {
      margin-top: 0.4rem;
      padding: 0.5rem;
    }
  }
  @media (min-width: 992px) and (max-width: 1199.99px) { 
  }
</style>
