<template>
    <div v-if="departments" id="departments">
        <app-bar page="Settings"></app-bar>
        <v-container>
          <v-card>
            <v-card-title class="px-4 pt-2">
              <settings-navigation class="border-bottom" page="Departments"></settings-navigation>
            </v-card-title>
          <v-card-text>
            <v-row class="top-bar mt-0 mb-3">
                <v-col class="py-0">
                    <v-btn class="create-btn my-2" min-width="220px" color="primary" elevation="0" @click="displayDialog">Opprett avdeling</v-btn>
                </v-col>
            </v-row>
            <v-simple-table v-if="departments.length > 0">
                <thead>
                    <tr>
                        <th>Navn</th>
                        <th>Aktive skift</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="department in filteredDepartments" :key="department.id" @click="populateDialog(department.id)">
                        <td>{{department.name}}</td>
                        <td>{{getShiftTypeNames(department)}}</td>
                    </tr>
                </tbody>
            </v-simple-table>
            <p v-else class="mt-5 text-center">Det er ikke registrert noen avdelinger for ditt verksted</p>
          </v-card-text>
          </v-card>
        </v-container>
            <v-dialog v-model="dialogVisible" persistent max-width="600px">
                <v-card>
                    <v-card-title class="justify-center text-uppercase pt-4 pb-0">{{dialogHeading}}</v-card-title>
                    <v-card-text>
                        <v-alert v-model="hasError" class="mt-2" dense dismissible type="error">{{errorMessage}}</v-alert>
                        <v-form ref="form">
                            <v-row>
                                <v-col>
                                    <v-text-field autofocus v-model="name" label="Navn" :rules="[value => !!value || 'Navn må fylles inn']" type="text" autocomplete="off" validate-on-blur/>
                                </v-col>
                            </v-row>
                            <v-autocomplete v-model="selectedShiftTypes" label="Aktive skift" chips deletable-chips multiple :items="formattedShiftTypes" autocomplete="off"></v-autocomplete>
                        </v-form>
                        <v-row class="mt-2">
                            <v-col><v-btn block color="primary" @click="save()">Lagre</v-btn></v-col>
                            <v-col><v-btn block color="warning" @click="closeDialog()">Avbryt</v-btn></v-col>
                            <v-col v-if="id"><v-btn block color="error" @click="showDeleteDialog()">Slett</v-btn></v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-dialog>
          <v-dialog v-model="deleteDialogVisible" persistent max-width="600px">
            <v-card>
              <v-card-title class="text-uppercase pt-5 pb-3">Slett avdeling</v-card-title>
              <v-card-text>
                <v-row>
                  <v-col>
                    <p>Skriv navnet på avdelingen du forsøker å slette (<b>{{name}}</b>) i tekstfeltet nedenfor for å bekrefte at du ønsker å slette denne avdelingen permanent.</p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field :placeholder="name" v-model="confirmDeleteText" autofocus />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col><v-btn block color="warning" @click="closeDeleteDialog()">Avbryt</v-btn></v-col>
                  <v-col><v-btn block color="error" :disabled="name !== confirmDeleteText" @click="deleteDepartment()">Slett avdeling</v-btn></v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-dialog>
        <feedback/>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import AppBar from '../components/AppBar.vue'
import SettingsNavigation from '../components/settings/SettingsNavigation.vue'
import Feedback from '../components/Feedback.vue'

export default {
  components: {
    AppBar,
    SettingsNavigation,
    Feedback,
  },
  computed: {
    ...mapState(['departments', 'shiftTypes']),
    filteredDepartments() {
      return this.departments.filter(
        department => department.name.toLowerCase().includes(this.search.toLowerCase()))
    },
    formattedShiftTypes() {
        let formattedShiftTypes = [];
        this.shiftTypes.forEach(shiftType => {
            formattedShiftTypes.push({'text': shiftType.name, 'value': shiftType.id})
        });
        return formattedShiftTypes;
    }
  },
  data: () => ({
    id: null,
    name: '',
    dialogVisible: false,
    deleteDialogVisible: false,
    confirmDeleteText: '',
    selectedShiftTypes: [],
    dialogHeading: 'Opprett oppgave',
    search: '',
    hasError: false,
    errorMessage: '',
    hours: null
  }),
  created() {
    this.$store.dispatch('getDepartments').then((response) => {
      if(response.data.status === 'SUCCESS') {
      }
    })
  },
  methods: {
    populateDialog(departmentId) {
      let department = this.departments.find(department => department.id === departmentId)
      this.id = department.id
      this.name = department.name
      this.dialogHeading = 'Rediger avdeling'
      this.dialogVisible = true
      this.selectedShiftTypes = department.shiftTypes.map(shiftType => shiftType.id)
    },
    getShiftTypeNames(department) {
        let shiftTypeNames = "";
        if(department.shiftTypes) {
            department.shiftTypes.forEach(shiftType => shiftTypeNames += shiftType.name + ", ")
            return shiftTypeNames.substring(0, shiftTypeNames.length - 2)
        }
    },
    save() {
      if(this.$refs.form.validate()) {
        let department = {
          name: this.name,
          shiftTypes: this.selectedShiftTypes
        }
        if(!this.id) {
          this.createDepartment(department)
        } else {
          department.id = this.id
          this.updateDepartment(department)
        }
      }
    },
    createDepartment(department) {
        this.$store.dispatch('createDepartment', department).then((response) => {
            if(response.data.status === 'SUCCESS') {
                this.closeDialog();
            } else {
                this.hasError = true;
                this.errorMessage = response.data.message;
            }
        })
    },
    updateDepartment(department) {
      this.$store.dispatch('updateDepartment', department).then((response) => {
        if(response.data.status === 'SUCCESS') {
          this.closeDialog();
        } else {
          this.hasError = true;
          this.errorMessage = response.data.message;
        }
      })
    },
    deleteDepartment() {
      this.$store.dispatch('deleteDepartment', this.id).then((response) => {
        if(response.data.status === 'SUCCESS') {
          this.closeDialog();
        } else {
          this.hasError = true;
          this.errorMessage = response.data.message;
        }
        this.closeDeleteDialog();
      })
    },
    showDeleteDialog() {
      this.dialogVisible = false;
      this.deleteDialogVisible = true;
    },
    closeDeleteDialog() {
      this.deleteDialogVisible = false;
      this.confirmDeleteText = '';
    },
    displayDialog() {
        this.dialogVisible = true
        this.dialogHeading = 'Opprett avdeling'
    },
    closeDialog() {
      this.$refs.form.reset()
      this.$refs.form.resetValidation()
      this.hasError = false;
      this.errorMessage = '';
      this.dialogVisible = false;
      this.deleteDialogVisible = false;
      this.id = null
    }
  }
}
</script>

<style lang="scss" scoped>

  tbody tr {
    cursor: pointer;
  }

  .border-bottom {
    border-bottom: thin solid rgba(0,0,0,.12);
  }

  @media (max-width: 575.99px) { 
    .create-btn {
      width: 100%;
    }
  }
  @media (min-width: 576px) and (max-width: 767.99px) { 

  }
  @media (min-width: 768px) and (max-width: 991.99px) {

  }
  @media (min-width: 992px) and (max-width: 1199.99px) { 

  }
</style>