var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{attrs:{"flat":"","color":"#33548c","height":"50px","max-height":"50"}},[_c('v-spacer'),(_vm.page == 'Dashboard')?_c('clients'):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"right-side",attrs:{"elevation":"0","small":""},on:{"click":function($event){return _vm.dashboard()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-calendar-month-outline")])],1)]}}])},[_c('span',[_vm._v("Plantavle")])]),(_vm.hasAccessToSettings())?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"right-side",attrs:{"elevation":"0","small":""},on:{"click":function($event){return _vm.settings()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-cog")])],1)]}}],null,false,3785030712)},[_c('span',[_vm._v("Instillinger")])]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"right-side",attrs:{"elevation":"0","small":""},on:{"click":function($event){return _vm.profile()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-account")])],1)]}}])},[_c('span',[_vm._v("Konto")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"right-side",attrs:{"elevation":"0","small":""},on:{"click":function($event){return _vm.logout()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-logout-variant")])],1)]}}])},[_c('span',[_vm._v("Logg av")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }