<template>
  <div id="profile">
    <app-bar page="Profile"></app-bar>
    <v-card id="profile-card" max-width="600" class="mx-auto">
      <v-form v-model="valid">
        <v-card-title class="justify-center text-uppercase pt-4 pb-0">Kontoinnstillinger</v-card-title>
        <v-card-text id="profile-text">
          <v-alert v-model="hasError" dense dismissible type="error">{{errorMessage}}</v-alert>
          <v-row>
            <v-col>
              <v-text-field
                v-model="firstName"
                label="Fornavn"
                :rules="[value => !!value || 'Fornavn må fylles inn']"
                validate-on-blur
                type="text"
                autocomplete="given-name"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                v-model="lastName"
                label="Etternavn"
                :rules="[value => !!value || 'Etternavn må fylles inn']"
                validate-on-blur
                type="text"
                autocomplete="family-name"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                v-model="email"
                label="E-post"
                :rules="emailRules"
                validate-on-blur
                type="email"
                autocomplete="email"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field 
                v-model="password"
                label="Nytt passord"
                :type="showPasswords ? 'text' : 'password'"
                :rules="passwordRules"
                :append-icon="showPasswords ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showPasswords = !showPasswords"
                validate-on-blur
                autocomplete="new-password"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field 
                v-model="repeatPassword"
                label="Gjenta passord"
                :type="showPasswords ? 'text' : 'password'"
                :rules="matchRules"
                :append-icon="showPasswords ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showPasswords = !showPasswords"
                validate-on-blur
                autocomplete="new-password"
                />
            </v-col>
          </v-row>
          <v-row class="mt-2">
            <v-col><v-btn type="button" :disabled="!valid" @click="save" block color="primary">Lagre</v-btn></v-col>
          </v-row>
        </v-card-text>
      </v-form>
    </v-card>
    <feedback/>
  </div>
</template>

<script>
import AppBar from '../components/AppBar.vue'
import Feedback from '../components/Feedback.vue'
import { mapState } from 'vuex'

export default {
  components: {
    AppBar,
    Feedback
  },  
  data: () => ({
    valid: false,
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    repeatPassword: '',
    showPasswords: false,
    hasError: false,
    errorMessage: '',
    emailRules: [
			value => !!value || 'E-post må fylles inn',
			value => {
				const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
				return pattern.test(value) || 'Må være en gyldig e-postadresse'
			},
		],
    passwordRules: [ 
      value => {
        if(value) {
          return !!value && value.length >= 8 ? true : 'Passordet må innholde minst 8 tegn'
        }
        return true;
      }
    ],
  }),
  created() {
      this.firstName = this.accessToken.user.firstName;
      this.lastName = this.accessToken.user.lastName;
      this.email = this.accessToken.user.email;
  },

  computed: {
    ...mapState(['accessToken']),
    matchRules() {
      return[
        value => {
          return value == this.password ? true : "Passordene må samsvare";
        }
      ]
    }
  },

  methods: {
    save() {
      this.$store.dispatch('updateProfile', 
      {
        id: this.accessToken.user.id,
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
        password: this.password
      }).then((response) => {
        if(response.data.status == 'ERROR') {
          this.password = '';
          this.repeatPassword = '';
          this.hasError = true;
          this.errorMessage = response.data.message;
        } else {
          this.hasError = false;
          this.errorMessage = '';
        }
      }).catch((error) => {
        console.warn(error)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  #profile-card {
    margin-top: 3rem;
    padding: 1rem;
  }

  @media (max-width: 575.99px) { 
    #profile-card {
      max-width: 90% !important;
      margin-top: 1.7rem;
      padding: 0.5rem;
    }
  }
  @media (min-width: 576px) and (max-width: 767.99px) { 
  }
  @media (min-width: 768px) and (max-width: 991.99px) {
    #profile-card {
      margin-top: 0.4rem;
      padding: 0.5rem;
    }
  }
  @media (min-width: 992px) and (max-width: 1199.99px) { 
  }
</style>