<template>
    <div class="task-types">
        <v-row class="task-row">
            <v-col class="task-col" v-for="taskType in taskTypes" :key="taskType.id">
                <v-chip class="task-type" :color="taskType.colorHexCode" label>{{taskType.name}}</v-chip>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    name: 'task-types',
    data: () => {
        return {
        }
    },
    computed: {
        ...mapState(['taskTypes']),
    },
}
</script>

<style>
.task-row {
    text-align: center !important;
    margin: 12px 12px;
}
.task-type {
    width: 100%;
}
.task-types .task-type {
    border: 2px solid #D3D3D3 !important;
    text-align: center;
    margin: 10px;
}
</style>