<template>
  <div v-if="equipment" id="equipment">
    <app-bar page="Settings"></app-bar>
    <v-container>
      <v-card>
          <v-card-title class="px-4 pt-2">
            <settings-navigation class="border-bottom" page="Equipment"></settings-navigation>
          </v-card-title>
        <v-card-text>
      <v-row class="top-bar mt-0 mb-3">
        <v-col class="py-0">
          <v-btn class="create-btn my-2" min-width="220px" color="primary" elevation="0" @click="dialogVisible = true">Opprett utstyr</v-btn>
        </v-col>
      </v-row>
      <v-simple-table v-if="equipment.length > 0">
        <thead>
          <tr>
            <th>Navn</th>
            <th>Antall</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="equipment in filteredEquipment" :key="equipment.id" @click="populateDialog(equipment.id)">
            <td>{{equipment.name}}</td>
            <td>{{equipment.amount}}</td>
          </tr>
        </tbody>
      </v-simple-table>
      <p v-else class="mt-5 text-center">Det er ikke registrert noe utstyr for ditt verksted</p>
        </v-card-text>
      </v-card>
    </v-container>
    <v-dialog v-model="dialogVisible" persistent max-width="600px">
      <v-card>
        <v-card-title class="justify-center text-uppercase pt-4 pb-0">{{dialogHeading}}</v-card-title>
        <v-card-text>
          <v-alert v-model="hasError" class="mt-2" dense dismissible type="error">{{errorMessage}}</v-alert>
          <v-form ref="form">
            <v-row>
              <v-col>
                <v-text-field
                  autofocus
                  v-model="name"
                  label="Navn"
                  :rules="[value => !!value || 'Navn må fylles inn']"
                  validate-on-blur
                  type="text"
                  autocomplete="off"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="amount"
                  label="Antall"
                  :rules="[value => !!value || 'Antall må fylles inn']"
                  validate-on-blur
                  type="number"
                  autocomplete="off"
                />
              </v-col>
            </v-row>
          </v-form>
          <v-row class="mt-2">
            <v-col><v-btn block color="primary" @click="save()">Lagre</v-btn></v-col>
            <v-col><v-btn block color="warning" @click="closeDialog()">Avbryt</v-btn></v-col>
            <v-col v-if="id"><v-btn block color="error" @click="showDeleteDialog()">Slett</v-btn></v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deleteDialogVisible" persistent max-width="600px">
      <v-card>
        <v-card-title class="text-uppercase pt-5 pb-3">Slett utstyr</v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <p>Skriv navnet på utstyret du forsøker å slette (<b>{{name}}</b>) i tekstfeltet nedenfor for å bekrefte at du ønsker å slette dette utstyret permanent.</p>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field :placeholder="name" v-model="confirmDeleteText" autofocus />
            </v-col>
          </v-row>
          <v-row>
            <v-col><v-btn block color="warning" @click="closeDeleteDialog()">Avbryt</v-btn></v-col>
            <v-col><v-btn block color="error" :disabled="name !== confirmDeleteText" @click="deleteEquipment()">Slett utstyr</v-btn></v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <feedback/>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import AppBar from '../components/AppBar.vue'
import SettingsNavigation from '../components/settings/SettingsNavigation.vue'
import Feedback from '../components/Feedback.vue'

export default {
  components: {
    AppBar,
    SettingsNavigation,
    Feedback
  },
  computed: {
    ...mapState(['equipment']),
    filteredEquipment() {
      return this.equipment.filter(
        equipment => equipment.name.toLowerCase().includes(this.search.toLowerCase()) || equipment.amount.toString().includes(this.search.toLowerCase()))
    },
    dialogHeading() {
      return this.id ? 'Rediger utstyr' : 'Opprett utstyr'
    },
  },
  data: () => ({
    id: null,
    name: '',
    amount: null,
    dialogVisible: false,
    deleteDialogVisible: false,
    confirmDeleteText: '',
    search: '',
    hasError: false,
    errorMessage: '',
  }),
  created() {
    this.$store.dispatch('getEquipment').then((response) => {
      if(response.data.status === 'SUCCESS') {
      }
    })
  },
  methods: {
    populateDialog(equipmentId) {

      let equipment = this.equipment.find(equipment => equipment.id === equipmentId)
      this.id = equipment.id
      this.name = equipment.name
      this.amount = equipment.amount
      this.dialogVisible = true
    },
    save() {
      if(this.$refs.form.validate()) {
        let equipment = {
          name: this.name,
          amount: this.amount,
        }
        if(!this.id) {
          this.createEquipment(equipment)
        } else {
          equipment.id = this.id
          this.updateEquipment(equipment)
        }
      }
    },
    createEquipment(equipment) {
      this.$store.dispatch('createEquipment', equipment).then((response) => {
        if(response.data.status === 'SUCCESS') {
          this.closeDialog();
        } else {
          this.hasError = true;
          this.errorMessage = response.data.message;
        }
      })
    },
    updateEquipment(equipment) {
      this.$store.dispatch('updateEquipment', equipment).then((response) => {
        if(response.data.status === 'SUCCESS') {
          this.closeDialog();
        } else {
          this.hasError = true;
          this.errorMessage = response.data.message;
        }
      })
    },
    deleteEquipment() {
      this.$store.dispatch('deleteEquipment', this.id).then((response) => {
        if(response.data.status === 'SUCCESS') {
          this.closeDialog();
        } else {
          this.hasError = true;
          this.errorMessage = response.data.message;
        }
        this.closeDeleteDialog();
      })
    },
    showDeleteDialog() {
      this.dialogVisible = false;
      this.deleteDialogVisible = true;
    },
    closeDeleteDialog() {
      this.deleteDialogVisible = false;
      this.confirmDeleteText = '';
    },
    closeDialog() {
      this.$refs.form.reset()
      this.$refs.form.resetValidation()
      this.hasError = false;
      this.errorMessage = '';
      this.dialogVisible = false;
      this.id = null
    }
  }
}
</script>

<style lang="scss" scoped>

  tbody tr {
    cursor: pointer;
  }

  .border-bottom {
    border-bottom: thin solid rgba(0,0,0,.12);
  }

  @media (max-width: 575.99px) { 
    .create-btn {
      width: 100%;
    }
  }
  @media (min-width: 576px) and (max-width: 767.99px) { 

  }
  @media (min-width: 768px) and (max-width: 991.99px) {

  }
  @media (min-width: 992px) and (max-width: 1199.99px) { 

  }
</style>