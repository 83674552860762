import Vue from 'vue'
import VueRouter from 'vue-router'
import store from './store'
import Login from './views/Login'
import Dashboard from './views/Dashboard'
import Users from './views//Users'
import ShiftTypes from './views/ShiftTypes'
import Departments from './views/Departments'
import Mechanics from './views/Mechanics'
import Equipment from './views/Equipment'
import TaskTypes from './views/TaskTypes'
import Profile from './views/Profile'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      minAccessLevel: 1
    }
  },
  {
    path: '/konto',
    name: 'Profile',
    component: Profile,
    meta: {
      minAccessLevel: 1
    }
  },
  {
    path: '/innstillinger',
    name: 'Settings',
    redirect : {
      name: 'Users'
    }
  },
  {
    path: '/innstillinger/brukere',
    name: 'Users',
    component: Users,
    meta: {
      minAccessLevel: 3
    }
  },
  {
    path: '/innstillinger/skift',
    name: 'ShiftTypes',
    component: ShiftTypes,
    meta: {
      minAccessLevel: 3
    }
  },
  {
    path: '/innstillinger/avdelinger',
    name: 'Departments',
    component: Departments,
    meta: {
      minAccessLevel: 3
    }
  },
  {
    path: '/innstillinger/mekanikere',
    name: 'Mechanics',
    component: Mechanics,
    meta: {
      minAccessLevel: 3
    }
  },
  {
    path: '/innstillinger/utstyr',
    name: 'Equipment',
    component: Equipment,
    meta: {
      minAccessLevel: 3
    }
  },
  {
    path: '/innstillinger/oppgaver',
    name: 'TaskTypes',
    component: TaskTypes,
    meta: {
      minAccessLevel: 3
    }
  },
  {
    path: '*',
    redirect: {
      name: 'Dashboard'
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  const { minAccessLevel } = to.meta ? to.meta : { minAccessLevel: null }
  const accessToken = store.state.accessToken
  if (minAccessLevel) {
    if (!accessToken) {
      next({ name: 'Login' })
      return
    }
    if (minAccessLevel > accessToken.user.accessLevel.level) {
      next({ path: 'Login' })
      return
    }
  }
  if (to.name == 'Login' && accessToken) {
    next({ name: 'Dashboard' })
    return
  }
  next()
})

export default router