import Vue from 'vue'
import Vuex from 'vuex'
import router from './router'
import BackendService from './service.js'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    accessToken: getFromLocalStorage('accessToken'),
    shifts: [],
    taskTypes: [],
    tasks: [],
    equipment: [],
    clients: [],
    notes: [],
    feedback: null,
    reservationTask: Object,
    selectedTask: Object,
    removedTask: Object,
    copiedTask: null,
    week: String,
    year: String,
    monday: String,

    users: null,
    mechanics: null,
    departments: null,
    shiftTypes: null,
    accessLevels: null,
  },
  getters: {
    getTaskById: (state) => (id) => {
      return state.tasks.find(task => task.id === id)
    },
  },
  mutations: {
    setTime(state, payload) {
      state.monday = payload.monday
      state.week = payload.week
      state.year = payload.year
    },
    decrementWeek(state) {
      state.monday = Vue.moment(state.monday, 'L').add(-1, 'weeks').format('L')
      state.year = Vue.moment(state.monday, 'L').format('YYYY')
      state.week = Vue.moment(state.monday, 'L').format('ww')
    },
    incrementWeek(state) {
      state.monday = Vue.moment(state.monday, 'L').add(1, 'weeks').format('L')
      state.year = Vue.moment(state.monday, 'L').format('YYYY')
      state.week = Vue.moment(state.monday, 'L').format('ww')
    },
    setFeedback(state, payload) {
      state.feedback = payload;
    },
    setClients(state, payload) {
      state.clients = payload;
    },
    setAccessToken(state, payload) {
      if (payload == null) {
        localStorage.clear()
        state.accessToken = null
      } else {
        localStorage.setItem('accessToken', JSON.stringify(payload))
        state.accessToken = payload
      }
    },
    setShifts(state, payload) {
      if (payload == null) {
        state.shifts = null;
        state.tasks = null;
        state.notes = null;
      } else {
        state.shifts = payload.shifts;
        state.tasks = payload.tasks;
        state.taskTypes = payload.taskTypes;
        state.equipment = payload.equipment;
        state.notes = payload.notes;
      }
    },
    setSettings(state, payload) {
      state.settings = payload;
    },

    // Settings - Users

    setUsersSettings(state, payload) {
      state.users = payload;
    },
    addUserSettings(state, payload) {
      state.users.push(payload);
    },
    updateUserSettings(state, payload) {
      let indexToUpdate = state.users.findIndex(x => x.id == payload.id);
      state.users.splice(indexToUpdate, 1, payload)
    },
    deleteUserSettings(state, payload) {
      var indexToDelete = state.users.findIndex(x => x.id == payload.id);
      if(indexToDelete >= 0) {
        state.users.splice(indexToDelete, 1);
      }
    },
    setAccessLevelsSettings(state, payload) {
      state.accessLevels = payload;
    },

    // Settings - ShiftTypes

    setShiftTypesSettings(state, payload) {
      state.shiftTypes = payload;
    },
    addShiftTypeSettings(state, payload) {
      state.shiftTypes.push(payload);
    },
    updateShiftTypeSettings(state, payload) {
      let indexToUpdate = state.shiftTypes.findIndex(x => x.id == payload.id);
      state.shiftTypes.splice(indexToUpdate, 1, payload)
    },
    deleteShiftTypeSettings(state, payload) {
      var indexToDelete = state.shiftTypes.findIndex(x => x.id == payload.id);
      if(indexToDelete >= 0) {
        state.shiftTypes.splice(indexToDelete, 1);
      }
    },

    // Settings - Departments

    setDepartmentsSettings(state, payload) {
      state.departments = payload;
    },
    addDepartmentSettings(state, payload) {
      state.departments.push(payload);
    },
    updateDepartmentSettings(state, payload) {
      let indexToUpdate = state.departments.findIndex(x => x.id == payload.id);
      state.departments.splice(indexToUpdate, 1, payload)
    },
    deleteDepartmentSettings(state, payload) {
      var indexToDelete = state.departments.findIndex(x => x.id == payload.id);
      if(indexToDelete >= 0) {
        state.departments.splice(indexToDelete, 1);
      }
    },

    // Settings - Mechanics

    setMechanicsSettings(state, payload) {
      state.mechanics = payload;
    },
    setShiftTypesSettings(state, payload) {
      state.shiftTypes = payload;
    },
    addMechanicSettings(state, payload) {
      state.mechanics.push(payload);
    },
    updateMechanicSettings(state, payload) {
      let indexToUpdate = state.mechanics.findIndex(x => x.id == payload.id);
      state.mechanics.splice(indexToUpdate, 1, payload)
    },
    deleteMechanicSettings(state, payload) {
      var indexToDelete = state.mechanics.findIndex(x => x.id == payload.id);
      if(indexToDelete >= 0) {
        state.mechanics.splice(indexToDelete, 1);
      }
    },

    // Settings - Equipment

    setEquipmentSettings(state, payload) {
      state.equipment = payload;
    },
    addEquipmentSettings(state, payload) {
      state.equipment.push(payload);
    },
    updateEquipmentSettings(state, payload) {
      let indexToUpdate = state.equipment.findIndex(x => x.id == payload.id);
      state.equipment.splice(indexToUpdate, 1, payload)
    },
    deleteEquipmentSettings(state, payload) {
      var indexToDelete = state.equipment.findIndex(x => x.id == payload.id);
      if(indexToDelete >= 0) {
        state.equipment.splice(indexToDelete, 1);
      }
    },

    // Settings TaskTypes

    setTaskTypesSettings(state, payload) {
      state.taskTypes = payload;
    },
    addTaskTypeSettings(state, payload) {
      state.taskTypes.push(payload);
    },
    updateTaskTypeSettings(state, payload) {
      let indexToUpdate = state.taskTypes.findIndex(x => x.id == payload.id);
      state.taskTypes.splice(indexToUpdate, 1, payload)
    },
    deleteTaskTypeSettings(state, payload) {
      var indexToDelete = state.taskTypes.findIndex(x => x.id == payload.id);
      if(indexToDelete >= 0) {
        state.taskTypes.splice(indexToDelete, 1);
      }
    },

    setShiftMechanicShift(state, payload){
      var sourceShift = state.shifts.find(element => element.id == payload.sourceShiftId);
      var targetShift = state.shifts.find(element => element.id == payload.targetShiftId);
      if(sourceShift) {
        var shiftMechanics = sourceShift.shiftMechanics;
        for(var i = 0; i < shiftMechanics.length; i++) {
          var shiftMechanic = shiftMechanics[i];
          if(shiftMechanic.id == payload.shiftMechanicId) {
            shiftMechanics.splice(i,1);
            targetShift.shiftMechanics.push(shiftMechanic);
            targetShift.shiftMechanics.sort(function(a,b) {return (a.mechanic.name > b.mechanic.name) ? 1 : ((b.mechanic.name > a.mechanic.name) ? -1 : 0);} )
            break;
          }
        }
      }
    },

    addTask(state, payload) {
      state.tasks.push(payload)
    },
    updateTask(state, task) {
      var indexToUpdate = state.tasks.findIndex(x => x.id == task.id);
      state.removedTask = state.tasks[indexToUpdate];
      state.tasks[indexToUpdate] = task;
    },
    deleteTask(state, task) {
      var indexToDelete = state.tasks.findIndex(x => x.id == task.id);
      if(indexToDelete >= 0) {
        state.removedTask = state.tasks[indexToDelete];
        state.tasks.splice(indexToDelete, 1);
      }
    },
    updateNote(state, note) {
      var indexToUpdate = state.notes.findIndex(x => x.id == note.id);
      if(indexToUpdate >= 0) {
        state.notes[indexToUpdate].content = note.content;
      }
    },
    setReservationTask(state, payload) {
      state.reservationTask = payload;
    },
    setSelectedTask(state, payload) {
      state.selectedTask = payload;
    },
    setCopiedTask(state, payload) {
      state.copiedTask = payload;
    },
    updateCustomer (state, payload) {
      state.selectedTask.customer = payload
    },
    updateShiftMechanicName (state, payload) {
      state.selectedTask.shiftMechanicName = payload
    },
    updateShiftMechanicId (state, payload) {
      state.selectedTask.shiftMechanicId = payload
    },
    updateDate (state, payload) {
      state.selectedTask.date = payload
    },
    updatePlateNumber (state, payload) {
      state.selectedTask.plateNumber = payload
    },
    updateTaskType (state, payload) {
      state.selectedTask.taskType = payload
    },
    updateShiftType (state, payload) {
      state.selectedTask.shiftType = payload
    },
    updateShiftId (state, payload) {
      state.selectedTask.shiftId = payload
    },
    updateDuration (state, payload) {
      state.selectedTask.duration = payload
    },
    updateStartHour (state, payload) {
      state.selectedTask.startHour = payload
    },
    updateEndHour (state, payload) {
      state.selectedTask.endHour = payload
    },
  },
  actions: {
    login({ commit }, { email, password }) {
      var credentials = new FormData()
      credentials.append('email', email)
      credentials.append('password', password)
      return BackendService.login(credentials)
        .then((response) => {
          if (response.data.status === 'error') {
          } else {
            commit('setAccessToken', response.data.data.accessToken)
          }
          return response
        })
        .catch((error) => error.response)
    },
    logout({ commit }, {infoText, error}) {
      BackendService.logout()
        .then(() => {
        })
        .catch((error) => {
          console.warn(error)
        })
      commit('setAccessToken', null)
      router.push({
        name: 'Login',
        params: {
          'infoText': infoText,
          'error': error
        }
      })
    },
    getWeekData({ commit }, {year, week, monday}) {
      return BackendService.getWeekData(this.state.accessToken.user.workshop.id, year, week, monday).then((response) => {
        if(response.data.status === 'SUCCESS') {
          commit('setShifts', response.data.data)
        }
        return response
      }).catch((error) => error.response)
    },
    getSettings({commit}) {
      return BackendService.getSettings(this.state.accessToken.user.workshop.id).then((response) => {
        if(response.data.status === 'SUCCESS') {
          commit('setSettings', response.data.data)
        }
        return response
      }).catch((error) => error.response)
    },

    // Users

    getUsers({commit}) {
      return BackendService.getUsers(this.state.accessToken.user.workshop.id).then((response) => {
        if(response.data.status === 'SUCCESS') {
          commit('setUsersSettings', response.data.data.users)
          commit('setAccessLevelsSettings', response.data.data.accessLevels)
        }
        return response
      }).catch((error) => error.response)
    },

    createUser({ commit }, user) {
      var formData = new FormData()
      formData.append('object.firstName', user.firstName)
      formData.append('object.lastName', user.lastName)
      formData.append('object.email', user.email)
      formData.append('object.accessLevel', user.accessLevel)
      formData.append('password', user.password)
      return BackendService.createUser(this.state.accessToken.user.workshop.id, formData).then((response) => {
        if(response.data.status === 'SUCCESS') {
          commit('addUserSettings', response.data.data.user)
          commit('setFeedback', response.data.message)
        }
        return response
      }).catch((error) => error.response)
    },

    updateUser({ commit }, user) {
      var formData = new FormData()
      formData.append('object.firstName', user.firstName)
      formData.append('object.lastName', user.lastName)
      formData.append('object.email', user.email)
      formData.append('object.accessLevel', user.accessLevel)
      formData.append('password', user.password)
      return BackendService.updateUser(this.state.accessToken.user.workshop.id, user.id, formData).then((response) => {
        if(response.data.status === 'SUCCESS') {
          commit('updateUserSettings', response.data.data.user)
          commit('setFeedback', response.data.message)
        }
        return response
      }).catch((error) => error.response)
    },

    deleteUser({ commit }, userId) {
      return BackendService.deleteUser(this.state.accessToken.user.workshop.id, userId).then((response) => {
        if(response.data.status === 'SUCCESS') {
          commit('deleteUserSettings', response.data.data.user)
          commit('setFeedback', response.data.message)
        }
        return response
      }).catch((error) => error.response)
    },

    // ShiftTypes

    getShiftTypes({commit}) {
      return BackendService.getShiftTypes(this.state.accessToken.user.workshop.id).then((response) => {
        if(response.data.status === 'SUCCESS') {
          commit('setShiftTypesSettings', response.data.data.shiftTypes)
        }
        return response
      }).catch((error) => error.response)
    },

    createShiftType({ commit }, shiftType) {
      var formData = new FormData()
      formData.append('object.name', shiftType.name)
      formData.append('startTime', shiftType.startTime)
      formData.append('endTime', shiftType.endTime)
      return BackendService.createShiftType(this.state.accessToken.user.workshop.id, formData).then((response) => {
        if(response.data.status === 'SUCCESS') {
          commit('addShiftTypeSettings', response.data.data.shiftType)
          commit('setFeedback', response.data.message)
        }
        return response
      }).catch((error) => error.response)
    },

    updateShiftType({ commit }, shiftType) {
      var formData = new FormData()
      formData.append('object.name', shiftType.name)
      formData.append('startTime', shiftType.startTime)
      formData.append('endTime', shiftType.endTime)
      return BackendService.updateShiftType(this.state.accessToken.user.workshop.id, shiftType.id, formData).then((response) => {
        if(response.data.status === 'SUCCESS') {
          commit('updateShiftTypeSettings', response.data.data.shiftType)
          commit('setFeedback', response.data.message)
        }
        return response
      }).catch((error) => error.response)
    },

    deleteShiftType({ commit }, shiftTypeId) {
      return BackendService.deleteShiftType(this.state.accessToken.user.workshop.id, shiftTypeId).then((response) => {
        if(response.data.status === 'SUCCESS') {
          commit('deleteShiftTypeSettings', response.data.data.shiftType)
          commit('setFeedback', response.data.message)
        }
        return response
      }).catch((error) => error.response)
    },

    // departments

    getDepartments({commit}) {
      return BackendService.getDepartments(this.state.accessToken.user.workshop.id).then((response) => {
        if(response.data.status === 'SUCCESS') {
          commit('setDepartmentsSettings', response.data.data.departments)
          commit('setShiftTypesSettings', response.data.data.shiftTypes)
        }
        return response
      }).catch((error) => error.response)
    },

    createDepartment({ commit }, department) {
      var formData = new FormData()
      formData.append('object.name', department.name)
      formData.append('shiftTypeIds', department.shiftTypes)
      return BackendService.createDepartment(this.state.accessToken.user.workshop.id, formData).then((response) => {
        if(response.data.status === 'SUCCESS') {
          commit('addDepartmentSettings', response.data.data.department)
          commit('setFeedback', response.data.message)
        }
        return response
      }).catch((error) => error.response)
    },

    updateDepartment({ commit }, department) {
      var formData = new FormData()
      formData.append('object.name', department.name)
      formData.append('shiftTypeIds', department.shiftTypes)
      return BackendService.updateDepartment(this.state.accessToken.user.workshop.id, department.id, formData).then((response) => {
        if(response.data.status === 'SUCCESS') {
          commit('updateDepartmentSettings', response.data.data.department)
          commit('setFeedback', response.data.message)
        }
        return response
      }).catch((error) => error.response)
    },

    deleteDepartment({ commit }, departmentId) {
      return BackendService.deleteDepartment(this.state.accessToken.user.workshop.id, departmentId).then((response) => {
        if(response.data.status === 'SUCCESS') {
          commit('deleteDepartmentSettings', response.data.data.department)
          commit('setFeedback', response.data.message)
        }
        return response
      }).catch((error) => error.response)
    },

    // Mechanics

    getMechanics({commit}) {
      return BackendService.getMechanics(this.state.accessToken.user.workshop.id).then((response) => {
        if(response.data.status === 'SUCCESS') {
          commit('setMechanicsSettings', response.data.data.mechanics)
          commit('setDepartmentsSettings', response.data.data.departments)
          commit('setShiftTypesSettings', response.data.data.shiftTypes)
        }
        return response
      }).catch((error) => error.response)
    },

    createMechanic({ commit }, mechanic) {
      var formData = new FormData()
      formData.append('object.name', mechanic.name)
      formData.append('object.employeeNumber', mechanic.employeeNumber != null ? mechanic.employeeNumber : 0)
      formData.append('departmentId', mechanic.department)
      formData.append('shiftTypeId', mechanic.shiftType)
      return BackendService.createMechanic(this.state.accessToken.user.workshop.id, formData).then((response) => {
        if(response.data.status === 'SUCCESS') {
          commit('addMechanicSettings', response.data.data.mechanic)
          commit('setFeedback', response.data.message)
        }
        return response
      }).catch((error) => error.response)
    },

    updateMechanic({ commit }, mechanic) {
      var formData = new FormData()
      formData.append('object.name', mechanic.name)
      formData.append('object.employeeNumber', mechanic.employeeNumber != null ? mechanic.employeeNumber : 0)
      formData.append('departmentId', mechanic.department)
      formData.append('shiftTypeId', mechanic.shiftType)
      return BackendService.updateMechanic(this.state.accessToken.user.workshop.id, mechanic.id, formData).then((response) => {
        if(response.data.status === 'SUCCESS') {
          commit('updateMechanicSettings', response.data.data.mechanic)
          commit('setFeedback', response.data.message)
        }
        return response
      }).catch((error) => error.response)
    },
    
    deleteMechanic({ commit }, mechanicId) {
      return BackendService.deleteMechanic(this.state.accessToken.user.workshop.id, mechanicId).then((response) => {
        if(response.data.status === 'SUCCESS') {
          commit('deleteMechanicSettings', response.data.data.mechanic)
          commit('setFeedback', response.data.message)
        }
        return response
      }).catch((error) => error.response)
    },

    // Equipment

    getEquipment({commit}) {
      return BackendService.getEquipment(this.state.accessToken.user.workshop.id).then((response) => {
        if(response.data.status === 'SUCCESS') {
          commit('setEquipmentSettings', response.data.data.equipment)
        }
        return response
      }).catch((error) => error.response)
    },

    createEquipment({ commit }, equipment) {
      var formData = new FormData()
      formData.append('object.name', equipment.name)
      formData.append('object.amount', equipment.amount)
      return BackendService.createEquipment(this.state.accessToken.user.workshop.id, formData).then((response) => {
        if(response.data.status === 'SUCCESS') {
          commit('addEquipmentSettings', response.data.data.equipment)
          commit('setFeedback', response.data.message)
        }
        return response
      }).catch((error) => error.response)
    },

    updateEquipment({ commit }, equipment) {
      var formData = new FormData()
      formData.append('object.name', equipment.name)
      formData.append('object.amount', equipment.amount)
      return BackendService.updateEquipment(this.state.accessToken.user.workshop.id, equipment.id, formData).then((response) => {
        if(response.data.status === 'SUCCESS') {
          commit('updateEquipmentSettings', response.data.data.equipment)
          commit('setFeedback', response.data.message)
        }
        return response
      }).catch((error) => error.response)
    },
    
    deleteEquipment({ commit }, equipmentId) {
      return BackendService.deleteEquipment(this.state.accessToken.user.workshop.id, equipmentId).then((response) => {
        if(response.data.status === 'SUCCESS') {
          commit('deleteEquipmentSettings', response.data.data.equipment)
          commit('setFeedback', response.data.message)
        }
        return response
      }).catch((error) => error.response)
    },

    // TaskTypes

    getTaskTypes({commit}) {
      return BackendService.getTaskTypes(this.state.accessToken.user.workshop.id).then((response) => {
        if(response.data.status === 'SUCCESS') {
          commit('setTaskTypesSettings', response.data.data.taskTypes)
          commit('setEquipmentSettings', response.data.data.equipment)
        }
        return response
      }).catch((error) => error.response)
    },

    createTaskType({ commit }, taskType) {
      var formData = new FormData()
      formData.append('object.name', taskType.name)
      formData.append('object.colorHexCode', taskType.colorHexCode)
      formData.append('requiredEquipment', taskType.requiredEquipment)
      return BackendService.createTaskType(this.state.accessToken.user.workshop.id, formData).then((response) => {
        if(response.data.status === 'SUCCESS') {
          commit('addTaskTypeSettings', response.data.data.taskType)
          commit('setFeedback', response.data.message)
        }
        return response
      }).catch((error) => error.response)
    },

    updateTaskType({ commit }, taskType) {
      var formData = new FormData()
      formData.append('object.name', taskType.name)
      formData.append('object.colorHexCode', taskType.colorHexCode)
      formData.append('requiredEquipment', taskType.requiredEquipment)
      return BackendService.updateTaskType(this.state.accessToken.user.workshop.id, taskType.id, formData).then((response) => {
        if(response.data.status === 'SUCCESS') {
          commit('updateTaskTypeSettings', response.data.data.taskType)
          commit('setFeedback', response.data.message)
        }
        return response
      }).catch((error) => error.response)
    },

    deleteTaskType({ commit }, taskTypeId) {
      return BackendService.deleteTaskType(this.state.accessToken.user.workshop.id, taskTypeId).then((response) => {
        if(response.data.status === 'SUCCESS') {
          commit('deleteTaskTypeSettings', response.data.data.taskType)
          commit('setFeedback', response.data.message)
        }
        return response
      }).catch((error) => error.response)
    },

    updateProfile({ commit }, {id, firstName, lastName, email, password}) {
      var formData = new FormData()
      formData.append('object.firstName', firstName)
      formData.append('object.lastName', lastName)
      formData.append('object.email', email)
      formData.append('password', password)
      return BackendService.updateProfile(id, formData)
        .then((response) => {
          if (response.data.status === 'SUCCESS') {
            commit('setAccessToken', response.data.data.accessToken)
            commit('setFeedback', response.data.message)
          }
          return response
        })
        .catch((error) => error.response)
    },
  }
})

function getFromLocalStorage(item) {
  return localStorage.getItem(item) ? JSON.parse(localStorage.getItem(item)) : null
}
