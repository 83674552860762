<template>
    <v-simple-table class="note-table">
        <tbody>
            <tr>
                <td class="hidden-td first-td">
                <td class="hidden-td" v-for="n in 40" :key="n" v-bind:class="{'first-hour': n % 8 == 1}"></td>
            </tr>
            <tr>
                <td class="input-td first-td">
                <td class="input-td" :contenteditable="userHasAccess()" colspan="8" v-for="(note) in notes" :key="note.id" :data-id="note.id" @focus="selectNote" @blur="updateNote">{{note.content}}</td>
            </tr>
        </tbody>
    </v-simple-table>
</template>

<script>
import { mapState } from 'vuex'
import { webSocketMixin } from '../mixins/webSocketMixin'
export default {
    name: 'notes',
    props: ['webSocket'],
    mixins: [webSocketMixin],
    data: () => {
        return {
            selectedNote: null,
        }
    },
    computed: {
        ...mapState(['accessToken', 'notes']),
    },
    methods: {
        selectNote(e) {
            this.selectedNote = this.notes.find(note => note.id == e.srcElement.getAttribute('data-id'));
        },
        updateNote(e) {
            if(this.selectedNote.content !== e.srcElement.innerText) {
                if(!(typeof this.selectedNote.content == 'undefined' && !e.srcElement.innerText)) {
                    this.selectedNote.content = e.srcElement.innerText
                    e.srcElement.innerText = this.selectedNote.content
                    this.webSocket.send(this.createWebSocketMessage(this.$store.state.accessToken.token, 'UPDATE_NOTE', JSON.stringify(this.selectedNote)))
                }
            }
        },
        userHasAccess() {
            return this.accessToken.user.accessLevel.level >= 2;
        }
    }
}
</script>

<style>
    .note-table tr:hover {
        background-color: transparent !important;
    }
    .first-td {
        width: 200px !important;
        border-left: 1px solid rgba(0,0,0,.12);
    }
    .firstHour {
        border-left: thin solid rgba(0,0,0,.12);
    }
    .hidden-td {
        border-right: thin solid rgba(0,0,0,.12);
        border-bottom: thin solid rgba(0,0,0,.12);
        height: 0px !important;
    }
    .input-td {
        height: 64px !important;
        background-color: white;
        border-left: 2px solid rgba(0,0,0,.12);
        border-right: 1px solid rgba(0,0,0,.12);
        border-top: 1px solid rgba(0,0,0,.12);
        border-bottom: 2px solid rgba(0,0,0,.12);
    }
    .input-td:hover {
        background-color: none;
    }
    [contenteditable]:focus {
        outline: transparent !important;
        border-bottom: 2px solid #33548C;
        caret-color: #33548C;
    }
    html {
        overflow-y: auto !important;
    }
</style>