<template>
  <div v-if="users" id="users">
      <app-bar page="Settings"></app-bar>
      <v-container>
        <v-card>
          <v-card-title class="px-4 pt-2">
            <settings-navigation class="border-bottom" page="Users"></settings-navigation>
          </v-card-title>
          <v-card-text>
          <v-row class="top-bar mt-0 mb-3">
            <v-col class="py-0">
              <v-btn class="create-btn my-2" min-width="220px" color="primary" elevation="0" @click="dialogVisible = true">Opprett bruker</v-btn>
            </v-col>
          </v-row>
          <v-simple-table v-if="users.length > 0">
            <thead>
              <tr>
                <th>Navn</th>
                <th>E-post</th>
                <th>Rettighetsnivå</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="user in filteredUsers" :key="user.id" @click="populateDialog(user.id)">
                <td>{{user.firstName}} {{user.lastName}}</td>
                <td>{{user.email}}</td>
                <td>{{user.accessLevel.name}}</td>
              </tr>
            </tbody>
          </v-simple-table>
          <p v-else class="mt-5 text-center">Det er ikke registrert noen brukere for ditt verksted</p>
          </v-card-text>
        </v-card>
      </v-container>
    <v-dialog v-model="dialogVisible" persistent max-width="600px">
      <v-card>
        <v-card-title class="justify-center text-uppercase pt-4 pb-0">{{dialogHeading}}</v-card-title>
        <v-card-text>
          <v-alert v-model="hasError" class="mt-2" dense dismissible type="error">{{errorMessage}}</v-alert>
          <v-form ref="form">
            <v-row>
              <v-col>
                <v-text-field
                  autofocus
                  v-model="firstName"
                  label="Fornavn"
                  :rules="[value => !!value || 'Fornavn må fylles inn']"
                  validate-on-blur
                  type="text"
                  autocomplete="off"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="lastName"
                  label="Etternavn"
                  :rules="[value => !!value || 'Etternavn må fylles inn']"
                  validate-on-blur
                  type="text"
                  autocomplete="off"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="email"
                  label="E-post"
                  :rules="emailRules"
                  validate-on-blur
                  type="email"
                  autocomplete="off"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-select
                  :items="formattedAccessLevels"
                  v-model="accessLevel"
                  label="Rettighetsnivå"
                  :rules="[value => !!value || 'Rettighetsnivå må velges']"
                  autocomplete="off"
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field 
                  v-model="password"
                  :label="passwordLabel"
                  :type="showPasswords ? 'text' : 'password'"
                  :rules="id ? passwordRulesUpdate : passwordRulesCreate"
                  :append-icon="showPasswords ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="showPasswords = !showPasswords"
                  validate-on-blur
                  autocomplete="off"
                />
              </v-col>
            </v-row>
          </v-form>
          <v-row class="mt-2">
            <v-col><v-btn block color="primary" @click="save()">Lagre</v-btn></v-col>
            <v-col><v-btn block color="warning" @click="closeDialog()">Avbryt</v-btn></v-col>
            <v-col v-if="id"><v-btn block color="error" @click="showDeleteDialog()">Slett</v-btn></v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deleteDialogVisible" persistent max-width="600px">
      <v-card>
        <v-card-title class="text-uppercase pt-5 pb-3">Slett bruker</v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <p>Skriv fornavnet til brukeren du forsøker å slette (<b>{{firstName}}</b>) i tekstfeltet nedenfor for å bekrefte at du ønsker å slette denne brukeren permanent.</p>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                :placeholder="firstName"
                v-model="confirmDeleteText" 
                autofocus
                autocomplete="off"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col><v-btn block color="warning" @click="closeDeleteDialog()">Avbryt</v-btn></v-col>
            <v-col><v-btn block color="error" :disabled="firstName !== confirmDeleteText" @click="deleteUser()">Slett bruker</v-btn></v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <feedback/>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import AppBar from '../components/AppBar.vue'
import SettingsNavigation from '../components/settings/SettingsNavigation.vue'
import Feedback from '../components/Feedback.vue'

export default {
  components: {
    AppBar,
    SettingsNavigation,
    Feedback
  },
  computed: {
    ...mapState(['users', 'accessLevels']),
    formattedAccessLevels() {
      let formattedAccessLevels = [];
      this.accessLevels.forEach(accessLevel => {
        formattedAccessLevels.push({
          text: accessLevel.name,
          value: accessLevel.value,
        })
      })
      return formattedAccessLevels;
    },
    filteredUsers() {
      return this.users.filter(
        user => user.firstName.toLowerCase().includes(this.search.toLowerCase())
        || user.lastName.toLowerCase().includes(this.search.toLowerCase())
        || user.accessLevel.name.toLowerCase().includes(this.search.toLowerCase())
        )
    },
    dialogHeading() {
      return this.id ? 'Rediger bruker' : 'Opprett bruker'
    },
    passwordLabel() {
      return this.id ? 'Nytt passord' : 'Passord'
    }
  },
  data: () => ({
    id: null,
    firstName: '',
    lastName: '',
    email: '',
    accessLevel: null,
    password: '',
    repeatPassword: '',
    showPasswords: false,
    dialogVisible: false,
    deleteDialogVisible: false,
    confirmDeleteText: '',
    search: '',
    hasError: false,
    errorMessage: '',
    emailRules: [
			value => !!value || 'E-post må fylles inn',
			value => {
				const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
				return pattern.test(value) || 'Må være en gyldig e-postadresse'
			},
		],
    passwordRulesCreate: [ 
      value => !!value || 'Passord må fylles inn',
      value => !!value && value.length >= 8 ? true : 'Passordet må innholde minst 8 tegn'
    ],
    passwordRulesUpdate: [
       value => {
        if(value) {
          return !!value && value.length >= 8 ? true : 'Passordet må innholde minst 8 tegn'
        }
        return true;
      }
    ],
  }),
  created() {
    this.$store.dispatch('getUsers').then((response) => {
      if(response.data.status === 'SUCCESS') {
      }
    })
  },
  methods: {
    populateDialog(userId) {
      let user = this.users.find(user => user.id === userId)
      this.id = user.id
      this.firstName = user.firstName
      this.lastName = user.lastName
      this.email = user.email
      this.accessLevel = user.accessLevel.value
      this.dialogVisible = true
    },
    save() {
      if(this.$refs.form.validate()) {
        let user = {
          firstName: this.firstName,
          lastName: this.lastName,
          email: this.email,
          accessLevel: this.accessLevel,
        }
        if(this.password && this.password.length > 0) {
          user.password = this.password
        }
        if(!this.id) {
          this.createUser(user)
        } else {
          user.id = this.id
          this.updateUser(user)
        }
      }
    },
    createUser(user) {
      this.$store.dispatch('createUser', user).then((response) => {
        if(response.data.status === 'SUCCESS') {
          this.closeDialog();
        } else {
          this.hasError = true;
          this.errorMessage = response.data.message;
        }
      })
    },
    updateUser(user) {
      this.$store.dispatch('updateUser', user).then((response) => {
        if(response.data.status === 'SUCCESS') {
          this.closeDialog();
        } else {
          this.hasError = true;
          this.errorMessage = response.data.message;
        }
      })
    },
    deleteUser() {
      this.$store.dispatch('deleteUser', this.id).then((response) => {
        if(response.data.status === 'SUCCESS') {
          this.closeDialog();
        } else {
          this.hasError = true;
          this.errorMessage = response.data.message;
        }
        this.closeDeleteDialog();
      })
    },
    showDeleteDialog() {
      this.dialogVisible = false;
      this.deleteDialogVisible = true;
    },
    closeDeleteDialog() {
      this.deleteDialogVisible = false;
      this.confirmDeleteText = '';
    },
    closeDialog() {
      this.$refs.form.reset()
      this.$refs.form.resetValidation()
      this.hasError = false;
      this.errorMessage = '';
      this.deleteDialogVisible = false;
      this.dialogVisible = false;
      this.id = null
    }
  }
}
</script>

<style lang="scss" scoped>

  tbody tr {
    cursor: pointer;
  }

  .border-bottom {
    border-bottom: thin solid rgba(0,0,0,.12);
  }

  @media (max-width: 575.99px) { 
    .create-btn {
      width: 100%;
    }
  }
  @media (min-width: 576px) and (max-width: 767.99px) { 

  }
  @media (min-width: 768px) and (max-width: 991.99px) {

  }
  @media (min-width: 992px) and (max-width: 1199.99px) { 

  }
</style>