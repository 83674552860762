<template>
    <v-app>
        <router-view></router-view>
    </v-app>
</template>

<script>
export default {
}
</script>

<style lang="scss">
#app {
    background-color: #efefef;
}
h1 {
  font-size: 1.8rem;
}
h2 {
    font-size: 1.0rem;
}
</style>
