<template>
    <v-btn tile color="primary" text class="icon-button" @click="$emit('click');" v-bind:class="{ selected: selected }">
        <v-col cols="12">
            <v-row width="100%" class="icon-row">
                <v-icon large>{{icon}}</v-icon>
            </v-row>
            <v-row width="100%" class="text-row">
                <span class="body-1 text-row-content">{{text}}</span>
            </v-row>
        </v-col>
    {{selected}}
    </v-btn>
</template>

<script>
    export default {
        props: ['text', 'icon', 'selected'],
        name: 'icon-button'
    }
</script>

<style lang="scss" scoped>
    .icon-button {
        width: 100% !important;
        height: 100% !important;
        background-color: white;
        padding-bottom: 2px !important;
        .icon-row {
            place-content: center;
            margin-bottom: 12px;
            color: #737373;
        }
        .text-row {
            place-content: center;
            .text-row-content {
                color: #737373;
                font-size: 8px !important;
            }
        }
        .col {
            padding: 0;
        }
        .row {
            margin-top: 5px;
            margin-bottom: 0;
        }
    }
    .icon-button.selected {
        border-bottom: 2px solid #33548C;
        padding-bottom: 0px !important;
        .icon-row {
            color: #33548C;
        }
        .text-row {
            .text-row-content {
                color: #33548C;
            }
        }
    }
</style>