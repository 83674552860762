<template>
  <v-app v-if="shifts && taskTypes">
    <app-bar :webSocket="webSocket" page="Dashboard" @closeWebSocket="closeWebSocket"></app-bar>
    <dashboard-header @weekChanged='weekChanged()'></dashboard-header>
      <v-simple-table v-for="shift in shifts" dense :id="'shift-' + shift.id" class="shift-table" :data-id="shift.id" :key="shift.id">
        <thead>
          <tr>
            <th @drop='onDrop($event)' @dragover.prevent @dragenter.prevent>{{shift.shiftType.name}} ({{shift.department.name}})</th>
            <th v-for="n in getShiftLength(shift) * 5" :key="n">{{formatHour(shift.shiftType.startHour + (n-1) % getShiftLength(shift))}}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="shiftMechanic in shift.shiftMechanics" :key="shiftMechanic.id" :data-id="shiftMechanic.id" :data-name="shiftMechanic.mechanic.name">
            <td class="no-select" draggable="true" @dragstart="startDrag($event, shiftMechanic)" @drop='onDrop($event)' @dragover.prevent @dragenter.prevent>{{getMechanicName(shiftMechanic.mechanic)}}</td>
            <td class="no-select"  @contextmenu="showContextMenu" :id="shiftMechanic.id + '-' + getCellDate(shift, n) + '-' + getCellHour(shift, n)" @click="showTaskDialog" v-for="n in getShiftLength(shift) * 5" :key="n" :class="getCellClasses(shift, n)" :data-date="getCellDate(shift, n)" :data-hour="getCellHour(shift, n)"></td>
          </tr>
        </tbody>
      </v-simple-table>
    <notes :webSocket="webSocket"></notes>
    <task-dialog :webSocket="webSocket" :taskTypes="taskTypes" :visible="taskDialogVisible" @taskDialogClosed="taskDialogClosed" @taskSaved="taskSaved" @taskUpdated="taskUpdated" @taskDeleted="taskDeleted"></task-dialog>
    <feedback></feedback>
    <v-menu class="context-menu" v-model="contextMenuVisible" :position-x="contextMenuX" :position-y="contextMenuY" absolute offset-y>
      <v-list class="context-menu-list">
          <v-list-item @click="cutTask" :disabled='cutDisabled' link class="context-menu-item">
            <v-list-item-title><v-icon class="menu-icon">mdi-content-cut</v-icon>Klipp ut</v-list-item-title>
          </v-list-item>
          <v-list-item @click="copyTask" :disabled='copyDisabled' link class="context-menu-item">
            <v-list-item-title><v-icon class="menu-icon">mdi-content-copy</v-icon>Kopier</v-list-item-title>
          </v-list-item>
          <v-list-item @click="pasteTask" :disabled='pasteDisabled' link class="context-menu-item">
            <v-list-item-title><v-icon class="menu-icon">mdi-content-paste</v-icon>Lim inn</v-list-item-title>
          </v-list-item>
      </v-list>
    </v-menu>
  </v-app>
</template>

<script>
import { mapState } from 'vuex'
import { webSocketMixin } from '../mixins/webSocketMixin'
import { taskCollisionMixin } from '../mixins/taskCollisionMixin'
import TaskDialog from '../components/TaskDialog.vue'
import AppBar from '../components/AppBar.vue'
import Notes from '../components/Notes.vue'
import TaskTypes from '../components/TaskTypes.vue'
import Feedback from '../components/Feedback.vue'
import DashboardHeader from '../components/DashboardHeader.vue'

export default {
  components: {
    TaskDialog,
    AppBar,
    Notes,
    TaskTypes,
    Feedback,
    DashboardHeader,
  },

  mixins: [webSocketMixin, taskCollisionMixin],

  data: function () {
    return {
      webSocket: null,
      webSocketPingTimerId: null,
      contextMenuX: 0,
      contextMenuY: 0,
      copyDisabled: true,
      cutDisabled: true,
      pasteDisabled: true,
      rightClickedTD: null,
      rightClickedTaskId: null,
      taskDialogVisible: false,
      contextMenuVisible: false,
      reopenWebSocket: true,
    }
  },

  created() {
    this.$store.commit('setSelectedTask', null)
    this.initWebSocket()
  },

  computed: {
    ...mapState(['accessToken', 'shifts', 'taskTypes', 'tasks', 'notes', 'reservationTask', 'selectedTask', 'copiedTask', 'monday']),
  },

  methods: {
    initWebSocket() {
      console.log("Initializing WebSocket")
      let webSocketUrl = process.env.NODE_ENV === 'development' ? "ws://localhost:9000/ws" : ((window.location.protocol === "https:") ? "wss://" : "ws://") + window.location.host + "/ws"
      this.webSocket = new WebSocket(webSocketUrl)
      let that = this
      this.webSocket.onopen = function(event) {
        console.log("WebSocket was opened")
        that.webSocket.send(that.createWebSocketMessage(that.accessToken.token, 'AUTHENTICATE', null))
        that.keepWebSocketAlive()
      }
      this.webSocket.onclose = function(event) {
        console.log("WebSocket was closed")
        if(that.reopenWebSocket) {
          if (that.webSocketPingTimerId) {  
            clearTimeout(that.webSocketPingTimerId);  
          }
          setTimeout(function() {
            that.initWebSocket();
          }, 1000);
        }
      }
      this.webSocket.onmessage = function (event) {
        that.parseWebSocketResponse(event)
      }
    },
    keepWebSocketAlive() {
      if (this.webSocket.readyState == this.webSocket.OPEN) {  
        this.webSocket.send(this.createWebSocketMessage(this.accessToken.token, 'PING', null))
      }
      this.webSocketPingTimerId = setTimeout(this.keepWebSocketAlive, 18000);  
    },
    closeWebSocket() {
      this.reopenWebSocket = false;
      this.webSocket.close();
    },
    showContextMenu (e) {
      e.preventDefault()
      if(this.userHasAccess()) {
        this.contextMenuVisible = false
        this.contextMenuX = e.clientX
        this.contextMenuY = e.clientY
        this.rightClickedTD = e.target
        this.rightClickedTaskId = this.rightClickedTD.getAttribute('data-task-id')
        if (this.rightClickedTaskId) {
          this.copyDisabled = false
          this.cutDisabled = false
          this.pasteDisabled = true
        } else {
          this.copyDisabled = true
          this.cutDisabled = true
          this.pasteDisabled = this.copiedTask ? false : true
        }
        this.$nextTick(() => {
          this.contextMenuVisible = true
        })
      }
    },
    cutTask() {
      this.copyTask()
      if (this.copiedTask) {
        this.webSocket.send(this.createWebSocketMessage(this.accessToken.token, 'DELETE_TASK', JSON.stringify(this.copiedTask.id)))
      }
    },
    copyTask() {
      if (this.rightClickedTaskId) {
        let copiedTask = this.tasks.find(task => task.id == this.rightClickedTaskId)
        if (copiedTask) {
          this.$store.commit('setCopiedTask', copiedTask)
        }
      }
    },
    pasteTask() {
      let selectedTask = this.createSelectedTaskObject(this.rightClickedTD)
      selectedTask.taskType = this.copiedTask.taskType
      this.$store.commit('setSelectedTask', selectedTask)
      let duration = this.availableHours
      if (duration == 0) {
        this.$store.commit('setFeedback', 'Nødvendig utstyr er ikke ledig på valgt tidspunkt');
        return;
      }
      if (duration > this.copiedTask.duration) {
        duration = this.copiedTask.duration
      }
      let newTask = {
        customer: this.copiedTask.customer,
        plateNumber: this.copiedTask.plateNumber,
        shiftMechanicId: this.selectedTask.shiftMechanic.id,
        taskTypeId: this.copiedTask.taskType.id,
        startTime: this.$moment(selectedTask.date, 'dddd L').set({"hour": selectedTask.startHour, "minute": 0}).format('yyyy-MM-DDTHH:mm'),
        endTime: this.$moment(selectedTask.date, 'dddd L').set({"hour": (selectedTask.startHour + duration), "minute": 0}).format('yyyy-MM-DDTHH:mm')
      }
      this.$store.commit('setSelectedTask', null)
      this.webSocket.send(this.createWebSocketMessage(this.accessToken.token, 'CREATE_TASK', JSON.stringify(newTask)))
    },
    createReservationTask(selectedTask) {
      const message = {
        shiftMechanicId: selectedTask.shiftMechanic.id,
        date: this.$moment(selectedTask.date, 'dddd L').format('YYYY-MM-DD'),
        startHour: selectedTask.startHour
      }
      this.webSocket.send(this.createWebSocketMessage(this.accessToken.token, 'CREATE_RESERVATION_TASK', JSON.stringify(message)))
    },
    removeReservationTask() {
      if(this.reservationTask && this.reservationTask.id) {
        this.webSocket.send(this.createWebSocketMessage(this.accessToken.token, 'REMOVE_RESERVATION_TASK', JSON.stringify(this.reservationTask.id)))
      }
    },
    weekChanged() {
      this.drawTasks();
    },
    drawTasks() {
      for(let i = 0; i < this.tasks.length; i++) {
        const task = this.tasks[i];
        this.drawTask(task);
      }
    },
    drawTask(task) {
      let startCell = document.getElementById(task.cellId);
      startCell.innerHTML = task.customer + "\n" + task.plateNumber;
      startCell.colSpan = task.duration;
      startCell.style.backgroundColor = task.taskType.colorHexCode;
      startCell.setAttribute("data-task-id", task.id);
      for(let j = 0; j < task.occupiedCellIds.length; j++) {
        const occupiedCellId = task.occupiedCellIds[j]
        let cell = document.getElementById(occupiedCellId);
        cell.style.display = 'none';
      }
    },
    eraseTask(task) {
      let startCell = document.getElementById(task.cellId);
      startCell.innerHTML = ""
      startCell.colSpan = 1
      startCell.style.removeProperty('background-color');
      startCell.removeAttribute('data-task-id');
      for(let j = 0; j < task.occupiedCellIds.length; j++) {
        const occupiedCellId = task.occupiedCellIds[j]
        let cell = document.getElementById(occupiedCellId);
        cell.style.removeProperty('display')
      }
    },
    taskSaved(task) {
      this.drawTask(task)
    },
    taskUpdated(task) {
      this.eraseTask(this.$store.state.removedTask);
      this.drawTask(task)
    },
    taskDeleted() {
      this.eraseTask(this.$store.state.removedTask);
    },
    taskDialogClosed() {
      this.$store.commit('setSelectedTask', null)
      this.taskDialogVisible = false;
      this.removeReservationTask();
    },
    showTaskDialog: function(e) {
      if(this.userHasAccess()) {
        let selectedTask = this.createSelectedTaskObject(e.target)
        if(!selectedTask.id) {
          this.createReservationTask(selectedTask)
        }
        if(selectedTask.taskType.name !== 'Reservasjon') {
          this.$store.commit('setSelectedTask', selectedTask)
          this.taskDialogVisible = true
        }
      }
    },
    createSelectedTaskObject(target) {
      const shift = this.shifts.find(shift => shift.id == target.closest('.shift-table').getAttribute('data-id'))
      let selectedTask = this.tasks.find(task => task.id == target.getAttribute('data-task-id'))
      if(selectedTask == null) {
        selectedTask = {}
        selectedTask.customer = "";
        selectedTask.plateNumber = "";
        selectedTask.taskType = this.taskTypes[0];
        selectedTask.duration = 1;
      }
      selectedTask.shiftType = shift.shiftType
      selectedTask.shiftMechanic = shift.shiftMechanics.find(shiftMechanic => shiftMechanic.id == target.closest('tr').getAttribute('data-id'))
      selectedTask.date = this.capFirstLetter(this.$moment(target.getAttribute('data-date'), 'L').format('dddd L'))
      selectedTask.startHour = Number(target.getAttribute('data-hour'))
      return selectedTask;
    },
    startDrag: function(e, shiftMechanic) {
        e.dataTransfer.dropEffect = 'move'
        e.dataTransfer.effectAllowed = 'move'
        e.dataTransfer.setData('shiftMechanicId', shiftMechanic.id)
        e.dataTransfer.setData('sourceShiftId', e.target.closest('.shift-table').getAttribute('data-id'))
        e.dataTransfer.setData('text/plain', 'dummy')
    },
    onDrop: function(e) {
      if(this.userHasAccess()) {
        let data = {
          shiftMechanicId: e.dataTransfer.getData('shiftMechanicId'),
          targetShiftId: e.target.closest('.shift-table').getAttribute('data-id'),
          sourceShiftId: e.dataTransfer.getData('sourceShiftId')
        }
        if(data.sourceShiftId != data.targetShiftId) {
          this.webSocket.send(this.createWebSocketMessage(this.accessToken.token, 'UPDATE_SHIFT_MECHANIC_SHIFT', JSON.stringify(data)))
        }
      }
    },
    getShiftLength(shift) {
      return shift.shiftType.endHour - shift.shiftType.startHour
    },
    getCellClasses(shift, n) {
      const hour = shift.shiftType.startHour + (n-1) % this.getShiftLength(shift)
      if(hour == shift.shiftType.startHour) {
        return "first-hour"
      } else if(hour + 1 == shift.shiftType.endHour) { 
        return "last-hour"
      }
      return null
    },
    getCellDate(shift, n) {
      var shiftType = shift.shiftType
      var multiplier = Math.floor((n-1) / (shiftType.endHour - shiftType.startHour))
      return this.$moment(this.monday, 'L').add(multiplier, 'days').format('L')
    },
    formatHour(hour) {
      if(hour < 10) {
        hour = "0"+ hour
      }
      return hour + ":00"
    },
    capFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    },
    getCellHour(shift, n) {
      return shift.shiftType.startHour + (n-1) % this.getShiftLength(shift)
    },
    getMechanicName(mechanic) {
      return mechanic.employeeNumber ? mechanic.name + " (" + mechanic.employeeNumber + ")" : mechanic.name
    },
    userHasAccess() {
      return this.accessToken.user.accessLevel.level >= 2;
    }
  }
}
</script>

<style>
  .context-menu-list {
   margin: 0px; 
   padding: 0px;
  }
  .context-menu-item {
    min-height: 40px;
  }
  .context-menu-item .v-list-item__title {
    padding-top: 5px;
  }
  .menu-icon {
    padding-left: 14px;
    padding-right: 10px;
    margin-top: -4px;
  }
  .first-hour {
    border-left: 2px solid rgba(0,0,0,.12);
  }
  table {
    border-radius: 0px;
    table-layout: fixed;
  } 
  .shift-table th {
    background-color: lightgray;
  }
  .shift-table th:first-child { 
    width: 200px;
    text-align: left;
  }
  .shift-table td {
    border-right: thin solid rgba(0,0,0,.12);
    border-bottom: thin solid rgba(0,0,0,.12);
  }
  .shift-table td:first-child { 
    width: 200px;
    border-left: thin solid rgba(0,0,0,.12);
  }
  .shift-table td:not(:first-child) {
    line-height: 1;
    white-space:pre;
    overflow: hidden;
    font-size: .850rem !important;
    padding: 2px 0px 0px 0px !important;
    font-family: 'Roboto Mono';font-size: 22px;
  }
  .v-data-table__wrapper {
    overflow-x: hidden;
  }
  .no-select {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
    background: #d2e3ff;
  }
</style>