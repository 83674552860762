export const webSocketMixin = {
  methods: {
    createWebSocketMessage(accessToken, type, value) {
      return JSON.stringify({
        accessToken: accessToken,
        type: type,
        value: value
      })
    },
    parseWebSocketResponse(event) {
      let response = JSON.parse(event.data)
      if (response.type == 'FEEDBACK') {
        this.$store.commit('setFeedback', response.message)
      } else if (response.type === 'CREATE_RESERVATION_TASK') {
        const task = response.value;
        this.$store.commit('addTask', task);
        this.drawTask(task)
      } else if (response.type === 'SET_RESERVATION_TASK') {
        this.$store.commit('setReservationTask', response.value);
      } else if (response.type === 'REMOVE_RESERVATION_TASK') {
        const reservationTask = this.$store.getters.getTaskById(response.value);
        this.eraseTask(reservationTask);
        if (reservationTask && this.reservationTask && reservationTask.id == this.reservationTask.id) {
          this.$store.commit('setReservationTask', null);
        }
        this.$store.commit('deleteTask', reservationTask);
      } else if (response.type === 'CREATE_TASK') {
        const task = response.value;
        this.$store.commit('addTask', task);
        this.taskSaved(task)
      } else if (response.type === 'UPDATE_TASK') {
        const task = response.value;
        this.$store.commit('updateTask', task)
        this.taskUpdated(task);
      } else if (response.type == 'DELETE_TASK') {
        const task = this.$store.getters.getTaskById(response.value)
        this.$store.commit('deleteTask', task)
        this.taskDeleted()
      } else if (response.type == 'UPDATE_NOTE') {
        this.$store.commit('updateNote', response.value)
      } else if (response.type === 'LIST_USERS') {
        this.$store.commit('setClients', response.value)
      } else if (response.type === 'UPDATE_SHIFT_MECHANIC_SHIFT') {
        this.$store.commit('setShiftMechanicShift', response.value);
      } else if (response.type == 'CLOSE_TASK_DIALOG') {
        this.taskDialogClosed();
      } else if (response.type == 'INVALID_TOKEN') {
        if (this.webSocket && this.webSocket.readyState == this.webSocket.OPEN) {
          this.webSocket.close(); // <-- TODO has to be fixed?
        }
        this.$store.dispatch('logout', { infoText: 'Sesjonen din har utløpt og du har blitt logget av systemet', error: true})
      }
    }
  }
}
