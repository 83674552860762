<template>
    <v-row no-gutters>
        <v-col>
            <icon-button class="px-0" text="Brukere" icon="mdi-account" @click="users()" v-bind:class="{ selected: page === 'Users' }"></icon-button>
        </v-col>
        <v-col>
            <icon-button class="px-0" text="Skift" icon="mdi-calendar-month-outline" @click="shiftTypes()" v-bind:class="{ selected: page === 'ShiftTypes' }"></icon-button>
        </v-col>
        <v-col>
            <icon-button class="px-0" text="Avdelinger" icon="mdi-graph-outline" @click="departments()" v-bind:class="{ selected: page === 'Departments' }"></icon-button>
        </v-col>
        <v-col>
            <icon-button class="px-0" text="Mekanikere" icon="mdi-account-hard-hat" @click="mechanics()" v-bind:class="{ selected: page === 'Mechanics' }"></icon-button>
        </v-col>
        <v-col>
            <icon-button class="px-0" text="Utstyr" icon="mdi-hammer-wrench" @click="equipment()" v-bind:class="{ selected: page === 'Equipment' }"></icon-button>
        </v-col>
        <v-col>
            <icon-button class="px-0" text="Oppgaver" icon="mdi-calendar-check" @click="taskTypes()" v-bind:class="{ selected: page === 'TaskTypes' }"></icon-button>
        </v-col>
    </v-row>
</template>

<script>
    import IconButton from '../ui/IconButton.vue'

    export default {
        components: {
            IconButton,
        },
        props: ['page'],
        name: 'settings-navigation',
        methods: {
            users() {
                if(this.page !== 'Users') {
                    this.$router.push({name: 'Users'})
                }
            },
            shiftTypes() {
                if(this.page !== 'ShiftTypes') {
                    this.$router.push({name: 'ShiftTypes'})
                }
            },
            departments() {
                if(this.page !== 'Departments') {
                    this.$router.push({name: 'Departments'})
                }
            },
            mechanics() {
                if(this.page !== 'Mechanics') {
                    this.$router.push({name: 'Mechanics'})
                }
            },
            equipment() {
                if(this.page !== 'Equipment') {
                    this.$router.push({name: 'Equipment'})
                }
            },
            taskTypes() {
                if(this.page !== 'TaskTypes') {
                    this.$router.push({name: 'TaskTypes'})
                }
            }
        },
    }
</script>