<template>
    <div class="clients">
        
            <v-tooltip bottom v-for="(client, index) in clients" :key="index">
            <template v-slot:activator="{ on, attrs }">
                <v-avatar size="35" v-bind="attrs" v-on="on"><span>{{client.initials}}</span></v-avatar>
            </template>   
            <span>{{client.firstName + " " + client.lastName}}</span>
        </v-tooltip>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'clients',
  computed: {
    ...mapState(['clients']),
  },
  methods: {
  }
}
</script>

<style>
  .clients .v-avatar {
    font-size: 12px;
    color: white;
    margin-left: 5px !important;
    background-color: #0c2754 !important;
    /* -webkit-box-shadow: inset 0px 0px 3px 1px rgba(6,29,66,1) !important;
    -moz-box-shadow: inset 0px 0px 3px 1px rgba(6,29,66,1) !important;
    box-shadow: inset 0px 0px 3px 1px rgba(6,29,66,1) !important; */
  }
</style>