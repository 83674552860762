<template>
  <div v-if="mechanics" id="mechanics">
    <app-bar page="Settings"></app-bar>
    <v-container>
      <v-card>
          <v-card-title class="px-4 pt-2">
            <settings-navigation class="border-bottom" page="Mechanics"></settings-navigation>
          </v-card-title>
        <v-card-text>
      <v-row class="top-bar mt-0 mb-3">
        <v-col class="py-0">
          <v-btn class="create-btn my-2" min-width="220px" color="primary" elevation="0" @click="dialogVisible = true">Opprett mekaniker</v-btn>
        </v-col>
      </v-row>
      <v-simple-table v-if="mechanics.length > 0">
        <thead>
          <tr>
            <th>Navn</th>
            <th>Ansattnummer</th>
            <th>Avdeling</th>
            <th>Standardskift</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="mechanic in filteredMechanics" :key="mechanic.id" @click="populateDialog(mechanic.id)">
            <td>{{mechanic.name}}</td>
            <td>{{mechanic.employeeNumber}}</td>
            <td v-if="mechanic.department">{{mechanic.department.name}}</td><td v-else></td>
            <td v-if="mechanic.shiftType">{{mechanic.shiftType.name}}</td><td v-else></td>
          </tr>
        </tbody>
      </v-simple-table>
      <p v-else class="mt-5 text-center">Det er ikke registrert noen mekanikere for ditt verksted</p>
        </v-card-text>
      </v-card>
    </v-container>
      <v-dialog v-model="dialogVisible" persistent max-width="600px">
        <v-card>
          <v-card-title class="justify-center text-uppercase pt-4 pb-0">{{dialogHeading}}</v-card-title>
          <v-card-text>
            <v-alert v-model="hasError" class="mt-2" dense dismissible type="error">{{errorMessage}}</v-alert>
            <v-form ref="form">
              <v-row>
                <v-col>
                  <v-text-field
                    autofocus
                    v-model="name"
                    label="Navn"
                    :rules="[value => !!value || 'Navn må fylles inn']"
                    validate-on-blur
                    type="text"
                    autocomplete="off"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="employeeNumber"
                    label="Ansattnummer"
                    validate-on-blur
                    type="number"
                    autocomplete="off"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-select
                    :items="formattedDepartments"
                    v-model="department"
                    label="Avdeling"
                    :rules="[value => !!value || 'Avdeling må velges']"
                    autocomplete="off"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-select
                    :items="formattedShiftTypes"
                    v-model="shiftType"
                    label="Standardskift"
                    :rules="[value => !!value || 'Standardskift må velges']"
                    autocomplete="off"
                  ></v-select>
                </v-col>
              </v-row>
            </v-form>
            <v-row class="mt-2">
              <v-col><v-btn block color="primary" @click="save()">Lagre</v-btn></v-col>
              <v-col><v-btn block color="warning" @click="closeDialog()">Avbryt</v-btn></v-col>
              <v-col v-if="id"><v-btn block color="error" @click="showDeleteDialog()">Slett</v-btn></v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog v-model="deleteDialogVisible" persistent max-width="600px">
        <v-card>
          <v-card-title class="text-uppercase pt-5 pb-3">Slett mekaniker</v-card-title>
          <v-card-text>
            <v-row>
              <v-col>
                <p>Skriv navnet på mekanikeren du forsøker å slette (<b>{{name}}</b>) i tekstfeltet nedenfor for å bekrefte at du ønsker å slette denne mekanikeren permanent.</p>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field :placeholder="name" v-model="confirmDeleteText" autofocus />
              </v-col>
            </v-row>
            <v-row>
              <v-col><v-btn block color="warning" @click="closeDeleteDialog()">Avbryt</v-btn></v-col>
              <v-col><v-btn block color="error" :disabled="name !== confirmDeleteText" @click="deleteMechanic()">Slett mekaniker</v-btn></v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
    <feedback/>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import AppBar from '../components/AppBar.vue'
import SettingsNavigation from '../components/settings/SettingsNavigation.vue'
import Feedback from '../components/Feedback.vue'

export default {
  components: {
    AppBar,
    SettingsNavigation,
    Feedback
  },
  computed: {
    ...mapState(['mechanics', 'departments', 'shiftTypes']),
    filteredMechanics() {
      return this.mechanics.filter(
        mechanic => mechanic.name.toLowerCase().includes(this.search.toLowerCase())
        || mechanic.employeeNumber.toLowerCase().includes(this.search.toLowerCase())
        || mechanic.department.name.toLowerCase().includes(this.search.toLowerCase())
        || mechanic.shiftType.name.toLowerCase().includes(this.search.toLowerCase())
        )
    },
    formattedDepartments() {
      let formattedDepartments = [];
      this.departments.forEach(department => {
        formattedDepartments.push({
          text: department.name,
          value: department.id,
        })
      })
      return formattedDepartments;
    },
    formattedShiftTypes() {
      let formattedShiftTypes = [];
      this.shiftTypes.forEach(shiftType => {
        formattedShiftTypes.push({
          text: shiftType.name,
          value: shiftType.id,
        })
      })
      return formattedShiftTypes;
    },
    dialogHeading() {
      return this.id ? 'Rediger mekaniker' : 'Opprett mekaniker'
    },
  },
  data: () => ({
    id: null,
    name: '',
    employeeNumber: null,
    department: null,
    shiftType: null,
    dialogVisible: false,
    deleteDialogVisible: false,
    confirmDeleteText: '',
    search: '',
    hasError: false,
    errorMessage: '',
  }),
  created() {
    this.$store.dispatch('getMechanics').then((response) => {
      if(response.data.status === 'SUCCESS') {
      }
    })
  },
  methods: {
    populateDialog(mechanicId) {
      let mechanic = this.mechanics.find(mechanic => mechanic.id === mechanicId)
      this.id = mechanic.id
      this.name = mechanic.name
      this.employeeNumber = mechanic.employeeNumber
      this.department = mechanic.department != null ? mechanic.department.id : null
      this.shiftType = mechanic.shiftType != null ? mechanic.shiftType.id : null
      this.dialogVisible = true
    },
    save() {
      if(this.$refs.form.validate()) {
        let mechanic = {
          name: this.name,
          employeeNumber: this.employeeNumber,
          department: this.department,
          shiftType: this.shiftType,
        }
        if(!this.id) {
          this.createMechanic(mechanic)
        } else {
          mechanic.id = this.id
          this.updateMechanic(mechanic)
        }
      }
    },
    createMechanic(mechanic) {
      this.$store.dispatch('createMechanic', mechanic).then((response) => {
        if(response.data.status === 'SUCCESS') {
          this.closeDialog();
        } else {
          this.hasError = true;
          this.errorMessage = response.data.message;
        }
      })
    },
    updateMechanic(mechanic) {
      this.$store.dispatch('updateMechanic', mechanic).then((response) => {
        if(response.data.status === 'SUCCESS') {
          this.closeDialog();
        } else {
          this.hasError = true;
          this.errorMessage = response.data.message;
        }
      })
    },
    deleteMechanic() {
      this.$store.dispatch('deleteMechanic', this.id).then((response) => {
        if(response.data.status === 'SUCCESS') {
          this.closeDialog();
        } else {
          this.hasError = true;
          this.errorMessage = response.data.message;
        }
        this.closeDeleteDialog();
      })
    },
    showDeleteDialog() {
      this.dialogVisible = false;
      this.deleteDialogVisible = true;
    },
    closeDeleteDialog() {
      this.deleteDialogVisible = false;
      this.confirmDeleteText = '';
    },
    closeDialog() {
      this.$refs.form.reset()
      this.$refs.form.resetValidation()
      this.hasError = false;
      this.errorMessage = '';
      this.dialogVisible = false;
      this.id = null
    }
  }
}
</script>

<style lang="scss" scoped>

  tbody tr {
    cursor: pointer;
  }

  .border-bottom {
    border-bottom: thin solid rgba(0,0,0,.12);
  }

  @media (max-width: 575.99px) { 
    .create-btn {
      width: 100%;
    }
  }
  @media (min-width: 576px) and (max-width: 767.99px) { 

  }
  @media (min-width: 768px) and (max-width: 991.99px) {

  }
  @media (min-width: 992px) and (max-width: 1199.99px) { 

  }
</style>