<template>
    <v-app-bar flat color="#33548c" height="50px" max-height="50" >
        <v-spacer></v-spacer>
        <!-- <v-btn class="right-side" elevation="0" small><v-icon small class="mr-2">mdi-newspaper-variant-multiple-outline</v-icon>Nyheter</v-btn>    
        <v-btn class="right-side" elevation="0" small><v-icon small class="mr-2">mdi-chart-bar</v-icon>Statistikk</v-btn>    
        <v-btn class="right-side" elevation="0" small><v-icon small class="mr-2">mdi-cog</v-icon>Instillinger</v-btn>    
        <v-btn class="right-side" elevation="0" small><v-icon small class="mr-2">mdi-account</v-icon>Arild</v-btn>    
        <v-btn class="right-side" elevation="0" small><v-icon small class="mr-2">mdi-logout-variant</v-icon>Logg av</v-btn>  -->
        <clients v-if="page == 'Dashboard'"/>
        <!-- <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
                <v-btn class="right-side" elevation="0" small v-bind="attrs" v-on="on"><v-icon small>mdi-newspaper-variant-multiple-outline</v-icon></v-btn>
            </template>   
            <span>Nyheter</span>
        </v-tooltip>
        <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
                <v-btn class="right-side" elevation="0" small v-bind="attrs" v-on="on"><v-icon small>mdi-chart-bar</v-icon></v-btn>
            </template>   
            <span>Statistikk</span>
        </v-tooltip> -->
        <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
                <v-btn @click="dashboard()" class="right-side" elevation="0" small v-bind="attrs" v-on="on"><v-icon small>mdi-calendar-month-outline</v-icon></v-btn>
            </template>   
            <span>Plantavle</span>
        </v-tooltip>
        <v-tooltip bottom v-if="hasAccessToSettings()">
            <template v-slot:activator="{ on, attrs }">
                <v-btn @click="settings()" class="right-side" elevation="0" small v-bind="attrs" v-on="on"><v-icon small>mdi-cog</v-icon></v-btn>
            </template>   
            <span>Instillinger</span>
        </v-tooltip>
        <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
                <v-btn @click="profile()" class="right-side" elevation="0" small v-bind="attrs" v-on="on"><v-icon small>mdi-account</v-icon></v-btn>
            </template>   
            <span>Konto</span>
        </v-tooltip>
        <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
                <v-btn @click="logout()" class="right-side" elevation="0" small v-bind="attrs" v-on="on"><v-icon small>mdi-logout-variant</v-icon></v-btn>
            </template>   
            <span>Logg av</span>
        </v-tooltip>
    </v-app-bar>
</template>

<script>
import { mapState } from 'vuex'

import Clients from '../components/Clients.vue'

export default {
  name: 'app-bar',
  components: {
    Clients
  },
  computed: {
      ...mapState(['accessToken']),
  },
  props: ['webSocket', 'page'],
  methods: {
    dashboard() {
        if(this.page !== 'Dashboard') {
            this.$router.push({name: 'Dashboard'})
        }
    },
    settings() {
        if(this.page !== 'Settings') {
            if(this.webSocket && this.webSocket.readyState == this.webSocket.OPEN) {
                this.$emit('closeWebSocket');
            }
            this.$router.push({name: 'Settings'})
        }
    },
    profile() {
        if(this.page !== 'Profile') {
            if(this.webSocket && this.webSocket.readyState == this.webSocket.OPEN) {
                this.$emit('closeWebSocket');
            }
            this.$router.push({name: 'Profile'})
        }
    },
    logout() {
        if(this.webSocket && this.webSocket.readyState == this.webSocket.OPEN) {
            this.$emit('closeWebSocket');
        }
        this.$store.dispatch('logout', { infoText: '', error: false })
    },
    hasAccessToSettings() {
        return this.accessToken.user.accessLevel.level >= 3 ? true : false
    }
  }
}
</script>

<style lang="scss">

.right-side {
    margin-left: 11px !important;
}
.v-toolbar__content {
    padding-right: 11px !important;
}
.v-app-bar .v-btn {
    color: white !important;
    background-color: #0c2754 !important;
    -webkit-box-shadow: inset 0px 0px 3px 1px rgba(6,29,66,1) !important;
    -moz-box-shadow: inset 0px 0px 3px 1px rgba(6,29,66,1) !important;
    box-shadow: inset 0px 0px 3px 1px rgba(6,29,66,1) !important;
}
</style>